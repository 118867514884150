// Ionicons Variables
// --------------------------

$ionicons-font-path: "../fonts" !default;
$ionicons-font-family: "Ionicons" !default;
$ionicons-version: "1.5.0" !default;
$ionicons-prefix: ion- !default;

$ionicon-var-alert: "\f101";
$ionicon-var-alert-circled: "\f100";
$ionicon-var-android-add: "\f2c7";
$ionicon-var-android-add-contact: "\f2c6";
$ionicon-var-android-alarm: "\f2c8";
$ionicon-var-android-archive: "\f2c9";
$ionicon-var-android-arrow-back: "\f2ca";
$ionicon-var-android-arrow-down-left: "\f2cb";
$ionicon-var-android-arrow-down-right: "\f2cc";
$ionicon-var-android-arrow-forward: "\f30f";
$ionicon-var-android-arrow-up-left: "\f2cd";
$ionicon-var-android-arrow-up-right: "\f2ce";
$ionicon-var-android-battery: "\f2cf";
$ionicon-var-android-book: "\f2d0";
$ionicon-var-android-calendar: "\f2d1";
$ionicon-var-android-call: "\f2d2";
$ionicon-var-android-camera: "\f2d3";
$ionicon-var-android-chat: "\f2d4";
$ionicon-var-android-checkmark: "\f2d5";
$ionicon-var-android-clock: "\f2d6";
$ionicon-var-android-close: "\f2d7";
$ionicon-var-android-contact: "\f2d8";
$ionicon-var-android-contacts: "\f2d9";
$ionicon-var-android-data: "\f2da";
$ionicon-var-android-developer: "\f2db";
$ionicon-var-android-display: "\f2dc";
$ionicon-var-android-download: "\f2dd";
$ionicon-var-android-drawer: "\f310";
$ionicon-var-android-dropdown: "\f2de";
$ionicon-var-android-earth: "\f2df";
$ionicon-var-android-folder: "\f2e0";
$ionicon-var-android-forums: "\f2e1";
$ionicon-var-android-friends: "\f2e2";
$ionicon-var-android-hand: "\f2e3";
$ionicon-var-android-image: "\f2e4";
$ionicon-var-android-inbox: "\f2e5";
$ionicon-var-android-information: "\f2e6";
$ionicon-var-android-keypad: "\f2e7";
$ionicon-var-android-lightbulb: "\f2e8";
$ionicon-var-android-locate: "\f2e9";
$ionicon-var-android-location: "\f2ea";
$ionicon-var-android-mail: "\f2eb";
$ionicon-var-android-microphone: "\f2ec";
$ionicon-var-android-mixer: "\f2ed";
$ionicon-var-android-more: "\f2ee";
$ionicon-var-android-note: "\f2ef";
$ionicon-var-android-playstore: "\f2f0";
$ionicon-var-android-printer: "\f2f1";
$ionicon-var-android-promotion: "\f2f2";
$ionicon-var-android-reminder: "\f2f3";
$ionicon-var-android-remove: "\f2f4";
$ionicon-var-android-search: "\f2f5";
$ionicon-var-android-send: "\f2f6";
$ionicon-var-android-settings: "\f2f7";
$ionicon-var-android-share: "\f2f8";
$ionicon-var-android-social: "\f2fa";
$ionicon-var-android-social-user: "\f2f9";
$ionicon-var-android-sort: "\f2fb";
$ionicon-var-android-stair-drawer: "\f311";
$ionicon-var-android-star: "\f2fc";
$ionicon-var-android-stopwatch: "\f2fd";
$ionicon-var-android-storage: "\f2fe";
$ionicon-var-android-system-back: "\f2ff";
$ionicon-var-android-system-home: "\f300";
$ionicon-var-android-system-windows: "\f301";
$ionicon-var-android-timer: "\f302";
$ionicon-var-android-trash: "\f303";
$ionicon-var-android-user-menu: "\f312";
$ionicon-var-android-volume: "\f304";
$ionicon-var-android-wifi: "\f305";
$ionicon-var-aperture: "\f313";
$ionicon-var-archive: "\f102";
$ionicon-var-arrow-down-a: "\f103";
$ionicon-var-arrow-down-b: "\f104";
$ionicon-var-arrow-down-c: "\f105";
$ionicon-var-arrow-expand: "\f25e";
$ionicon-var-arrow-graph-down-left: "\f25f";
$ionicon-var-arrow-graph-down-right: "\f260";
$ionicon-var-arrow-graph-up-left: "\f261";
$ionicon-var-arrow-graph-up-right: "\f262";
$ionicon-var-arrow-left-a: "\f106";
$ionicon-var-arrow-left-b: "\f107";
$ionicon-var-arrow-left-c: "\f108";
$ionicon-var-arrow-move: "\f263";
$ionicon-var-arrow-resize: "\f264";
$ionicon-var-arrow-return-left: "\f265";
$ionicon-var-arrow-return-right: "\f266";
$ionicon-var-arrow-right-a: "\f109";
$ionicon-var-arrow-right-b: "\f10a";
$ionicon-var-arrow-right-c: "\f10b";
$ionicon-var-arrow-shrink: "\f267";
$ionicon-var-arrow-swap: "\f268";
$ionicon-var-arrow-up-a: "\f10c";
$ionicon-var-arrow-up-b: "\f10d";
$ionicon-var-arrow-up-c: "\f10e";
$ionicon-var-asterisk: "\f314";
$ionicon-var-at: "\f10f";
$ionicon-var-bag: "\f110";
$ionicon-var-battery-charging: "\f111";
$ionicon-var-battery-empty: "\f112";
$ionicon-var-battery-full: "\f113";
$ionicon-var-battery-half: "\f114";
$ionicon-var-battery-low: "\f115";
$ionicon-var-beaker: "\f269";
$ionicon-var-beer: "\f26a";
$ionicon-var-bluetooth: "\f116";
$ionicon-var-bonfire: "\f315";
$ionicon-var-bookmark: "\f26b";
$ionicon-var-briefcase: "\f26c";
$ionicon-var-bug: "\f2be";
$ionicon-var-calculator: "\f26d";
$ionicon-var-calendar: "\f117";
$ionicon-var-camera: "\f118";
$ionicon-var-card: "\f119";
$ionicon-var-cash: "\f316";
$ionicon-var-chatbox: "\f11b";
$ionicon-var-chatbox-working: "\f11a";
$ionicon-var-chatboxes: "\f11c";
$ionicon-var-chatbubble: "\f11e";
$ionicon-var-chatbubble-working: "\f11d";
$ionicon-var-chatbubbles: "\f11f";
$ionicon-var-checkmark: "\f122";
$ionicon-var-checkmark-circled: "\f120";
$ionicon-var-checkmark-round: "\f121";
$ionicon-var-chevron-down: "\f123";
$ionicon-var-chevron-left: "\f124";
$ionicon-var-chevron-right: "\f125";
$ionicon-var-chevron-up: "\f126";
$ionicon-var-clipboard: "\f127";
$ionicon-var-clock: "\f26e";
$ionicon-var-close: "\f12a";
$ionicon-var-close-circled: "\f128";
$ionicon-var-close-round: "\f129";
$ionicon-var-closed-captioning: "\f317";
$ionicon-var-cloud: "\f12b";
$ionicon-var-code: "\f271";
$ionicon-var-code-download: "\f26f";
$ionicon-var-code-working: "\f270";
$ionicon-var-coffee: "\f272";
$ionicon-var-compass: "\f273";
$ionicon-var-compose: "\f12c";
$ionicon-var-connection-bars: "\f274";
$ionicon-var-contrast: "\f275";
$ionicon-var-cube: "\f318";
$ionicon-var-disc: "\f12d";
$ionicon-var-document: "\f12f";
$ionicon-var-document-text: "\f12e";
$ionicon-var-drag: "\f130";
$ionicon-var-earth: "\f276";
$ionicon-var-edit: "\f2bf";
$ionicon-var-egg: "\f277";
$ionicon-var-eject: "\f131";
$ionicon-var-email: "\f132";
$ionicon-var-eye: "\f133";
$ionicon-var-eye-disabled: "\f306";
$ionicon-var-female: "\f278";
$ionicon-var-filing: "\f134";
$ionicon-var-film-marker: "\f135";
$ionicon-var-fireball: "\f319";
$ionicon-var-flag: "\f279";
$ionicon-var-flame: "\f31a";
$ionicon-var-flash: "\f137";
$ionicon-var-flash-off: "\f136";
$ionicon-var-flask: "\f138";
$ionicon-var-folder: "\f139";
$ionicon-var-fork: "\f27a";
$ionicon-var-fork-repo: "\f2c0";
$ionicon-var-forward: "\f13a";
$ionicon-var-funnel: "\f31b";
$ionicon-var-game-controller-a: "\f13b";
$ionicon-var-game-controller-b: "\f13c";
$ionicon-var-gear-a: "\f13d";
$ionicon-var-gear-b: "\f13e";
$ionicon-var-grid: "\f13f";
$ionicon-var-hammer: "\f27b";
$ionicon-var-happy: "\f31c";
$ionicon-var-headphone: "\f140";
$ionicon-var-heart: "\f141";
$ionicon-var-heart-broken: "\f31d";
$ionicon-var-help: "\f143";
$ionicon-var-help-buoy: "\f27c";
$ionicon-var-help-circled: "\f142";
$ionicon-var-home: "\f144";
$ionicon-var-icecream: "\f27d";
$ionicon-var-icon-social-google-plus: "\f146";
$ionicon-var-icon-social-google-plus-outline: "\f145";
$ionicon-var-image: "\f147";
$ionicon-var-images: "\f148";
$ionicon-var-information: "\f14a";
$ionicon-var-information-circled: "\f149";
$ionicon-var-ionic: "\f14b";
$ionicon-var-ios7-alarm: "\f14d";
$ionicon-var-ios7-alarm-outline: "\f14c";
$ionicon-var-ios7-albums: "\f14f";
$ionicon-var-ios7-albums-outline: "\f14e";
$ionicon-var-ios7-americanfootball: "\f31f";
$ionicon-var-ios7-americanfootball-outline: "\f31e";
$ionicon-var-ios7-analytics: "\f321";
$ionicon-var-ios7-analytics-outline: "\f320";
$ionicon-var-ios7-arrow-back: "\f150";
$ionicon-var-ios7-arrow-down: "\f151";
$ionicon-var-ios7-arrow-forward: "\f152";
$ionicon-var-ios7-arrow-left: "\f153";
$ionicon-var-ios7-arrow-right: "\f154";
$ionicon-var-ios7-arrow-thin-down: "\f27e";
$ionicon-var-ios7-arrow-thin-left: "\f27f";
$ionicon-var-ios7-arrow-thin-right: "\f280";
$ionicon-var-ios7-arrow-thin-up: "\f281";
$ionicon-var-ios7-arrow-up: "\f155";
$ionicon-var-ios7-at: "\f157";
$ionicon-var-ios7-at-outline: "\f156";
$ionicon-var-ios7-barcode: "\f323";
$ionicon-var-ios7-barcode-outline: "\f322";
$ionicon-var-ios7-baseball: "\f325";
$ionicon-var-ios7-baseball-outline: "\f324";
$ionicon-var-ios7-basketball: "\f327";
$ionicon-var-ios7-basketball-outline: "\f326";
$ionicon-var-ios7-bell: "\f159";
$ionicon-var-ios7-bell-outline: "\f158";
$ionicon-var-ios7-bolt: "\f15b";
$ionicon-var-ios7-bolt-outline: "\f15a";
$ionicon-var-ios7-bookmarks: "\f15d";
$ionicon-var-ios7-bookmarks-outline: "\f15c";
$ionicon-var-ios7-box: "\f15f";
$ionicon-var-ios7-box-outline: "\f15e";
$ionicon-var-ios7-briefcase: "\f283";
$ionicon-var-ios7-briefcase-outline: "\f282";
$ionicon-var-ios7-browsers: "\f161";
$ionicon-var-ios7-browsers-outline: "\f160";
$ionicon-var-ios7-calculator: "\f285";
$ionicon-var-ios7-calculator-outline: "\f284";
$ionicon-var-ios7-calendar: "\f163";
$ionicon-var-ios7-calendar-outline: "\f162";
$ionicon-var-ios7-camera: "\f165";
$ionicon-var-ios7-camera-outline: "\f164";
$ionicon-var-ios7-cart: "\f167";
$ionicon-var-ios7-cart-outline: "\f166";
$ionicon-var-ios7-chatboxes: "\f169";
$ionicon-var-ios7-chatboxes-outline: "\f168";
$ionicon-var-ios7-chatbubble: "\f16b";
$ionicon-var-ios7-chatbubble-outline: "\f16a";
$ionicon-var-ios7-checkmark: "\f16e";
$ionicon-var-ios7-checkmark-empty: "\f16c";
$ionicon-var-ios7-checkmark-outline: "\f16d";
$ionicon-var-ios7-circle-filled: "\f16f";
$ionicon-var-ios7-circle-outline: "\f170";
$ionicon-var-ios7-clock: "\f172";
$ionicon-var-ios7-clock-outline: "\f171";
$ionicon-var-ios7-close: "\f2bc";
$ionicon-var-ios7-close-empty: "\f2bd";
$ionicon-var-ios7-close-outline: "\f2bb";
$ionicon-var-ios7-cloud: "\f178";
$ionicon-var-ios7-cloud-download: "\f174";
$ionicon-var-ios7-cloud-download-outline: "\f173";
$ionicon-var-ios7-cloud-outline: "\f175";
$ionicon-var-ios7-cloud-upload: "\f177";
$ionicon-var-ios7-cloud-upload-outline: "\f176";
$ionicon-var-ios7-cloudy: "\f17a";
$ionicon-var-ios7-cloudy-night: "\f308";
$ionicon-var-ios7-cloudy-night-outline: "\f307";
$ionicon-var-ios7-cloudy-outline: "\f179";
$ionicon-var-ios7-cog: "\f17c";
$ionicon-var-ios7-cog-outline: "\f17b";
$ionicon-var-ios7-compose: "\f17e";
$ionicon-var-ios7-compose-outline: "\f17d";
$ionicon-var-ios7-contact: "\f180";
$ionicon-var-ios7-contact-outline: "\f17f";
$ionicon-var-ios7-copy: "\f182";
$ionicon-var-ios7-copy-outline: "\f181";
$ionicon-var-ios7-download: "\f184";
$ionicon-var-ios7-download-outline: "\f183";
$ionicon-var-ios7-drag: "\f185";
$ionicon-var-ios7-email: "\f187";
$ionicon-var-ios7-email-outline: "\f186";
$ionicon-var-ios7-expand: "\f30d";
$ionicon-var-ios7-eye: "\f189";
$ionicon-var-ios7-eye-outline: "\f188";
$ionicon-var-ios7-fastforward: "\f18b";
$ionicon-var-ios7-fastforward-outline: "\f18a";
$ionicon-var-ios7-filing: "\f18d";
$ionicon-var-ios7-filing-outline: "\f18c";
$ionicon-var-ios7-film: "\f18f";
$ionicon-var-ios7-film-outline: "\f18e";
$ionicon-var-ios7-flag: "\f191";
$ionicon-var-ios7-flag-outline: "\f190";
$ionicon-var-ios7-folder: "\f193";
$ionicon-var-ios7-folder-outline: "\f192";
$ionicon-var-ios7-football: "\f329";
$ionicon-var-ios7-football-outline: "\f328";
$ionicon-var-ios7-gear: "\f195";
$ionicon-var-ios7-gear-outline: "\f194";
$ionicon-var-ios7-glasses: "\f197";
$ionicon-var-ios7-glasses-outline: "\f196";
$ionicon-var-ios7-heart: "\f199";
$ionicon-var-ios7-heart-outline: "\f198";
$ionicon-var-ios7-help: "\f19c";
$ionicon-var-ios7-help-empty: "\f19a";
$ionicon-var-ios7-help-outline: "\f19b";
$ionicon-var-ios7-home: "\f32b";
$ionicon-var-ios7-home-outline: "\f32a";
$ionicon-var-ios7-infinite: "\f19e";
$ionicon-var-ios7-infinite-outline: "\f19d";
$ionicon-var-ios7-information: "\f1a1";
$ionicon-var-ios7-information-empty: "\f19f";
$ionicon-var-ios7-information-outline: "\f1a0";
$ionicon-var-ios7-ionic-outline: "\f1a2";
$ionicon-var-ios7-keypad: "\f1a4";
$ionicon-var-ios7-keypad-outline: "\f1a3";
$ionicon-var-ios7-lightbulb: "\f287";
$ionicon-var-ios7-lightbulb-outline: "\f286";
$ionicon-var-ios7-location: "\f1a6";
$ionicon-var-ios7-location-outline: "\f1a5";
$ionicon-var-ios7-locked: "\f1a8";
$ionicon-var-ios7-locked-outline: "\f1a7";
$ionicon-var-ios7-loop: "\f32d";
$ionicon-var-ios7-loop-strong: "\f32c";
$ionicon-var-ios7-medkit: "\f289";
$ionicon-var-ios7-medkit-outline: "\f288";
$ionicon-var-ios7-mic: "\f1ab";
$ionicon-var-ios7-mic-off: "\f1a9";
$ionicon-var-ios7-mic-outline: "\f1aa";
$ionicon-var-ios7-minus: "\f1ae";
$ionicon-var-ios7-minus-empty: "\f1ac";
$ionicon-var-ios7-minus-outline: "\f1ad";
$ionicon-var-ios7-monitor: "\f1b0";
$ionicon-var-ios7-monitor-outline: "\f1af";
$ionicon-var-ios7-moon: "\f1b2";
$ionicon-var-ios7-moon-outline: "\f1b1";
$ionicon-var-ios7-more: "\f1b4";
$ionicon-var-ios7-more-outline: "\f1b3";
$ionicon-var-ios7-musical-note: "\f1b5";
$ionicon-var-ios7-musical-notes: "\f1b6";
$ionicon-var-ios7-navigate: "\f1b8";
$ionicon-var-ios7-navigate-outline: "\f1b7";
$ionicon-var-ios7-paper: "\f32f";
$ionicon-var-ios7-paper-outline: "\f32e";
$ionicon-var-ios7-paperplane: "\f1ba";
$ionicon-var-ios7-paperplane-outline: "\f1b9";
$ionicon-var-ios7-partlysunny: "\f1bc";
$ionicon-var-ios7-partlysunny-outline: "\f1bb";
$ionicon-var-ios7-pause: "\f1be";
$ionicon-var-ios7-pause-outline: "\f1bd";
$ionicon-var-ios7-paw: "\f331";
$ionicon-var-ios7-paw-outline: "\f330";
$ionicon-var-ios7-people: "\f1c0";
$ionicon-var-ios7-people-outline: "\f1bf";
$ionicon-var-ios7-person: "\f1c2";
$ionicon-var-ios7-person-outline: "\f1c1";
$ionicon-var-ios7-personadd: "\f1c4";
$ionicon-var-ios7-personadd-outline: "\f1c3";
$ionicon-var-ios7-photos: "\f1c6";
$ionicon-var-ios7-photos-outline: "\f1c5";
$ionicon-var-ios7-pie: "\f28b";
$ionicon-var-ios7-pie-outline: "\f28a";
$ionicon-var-ios7-play: "\f1c8";
$ionicon-var-ios7-play-outline: "\f1c7";
$ionicon-var-ios7-plus: "\f1cb";
$ionicon-var-ios7-plus-empty: "\f1c9";
$ionicon-var-ios7-plus-outline: "\f1ca";
$ionicon-var-ios7-pricetag: "\f28d";
$ionicon-var-ios7-pricetag-outline: "\f28c";
$ionicon-var-ios7-pricetags: "\f333";
$ionicon-var-ios7-pricetags-outline: "\f332";
$ionicon-var-ios7-printer: "\f1cd";
$ionicon-var-ios7-printer-outline: "\f1cc";
$ionicon-var-ios7-pulse: "\f335";
$ionicon-var-ios7-pulse-strong: "\f334";
$ionicon-var-ios7-rainy: "\f1cf";
$ionicon-var-ios7-rainy-outline: "\f1ce";
$ionicon-var-ios7-recording: "\f1d1";
$ionicon-var-ios7-recording-outline: "\f1d0";
$ionicon-var-ios7-redo: "\f1d3";
$ionicon-var-ios7-redo-outline: "\f1d2";
$ionicon-var-ios7-refresh: "\f1d6";
$ionicon-var-ios7-refresh-empty: "\f1d4";
$ionicon-var-ios7-refresh-outline: "\f1d5";
$ionicon-var-ios7-reload: "\f28e";
$ionicon-var-ios7-reverse-camera: "\f337";
$ionicon-var-ios7-reverse-camera-outline: "\f336";
$ionicon-var-ios7-rewind: "\f1d8";
$ionicon-var-ios7-rewind-outline: "\f1d7";
$ionicon-var-ios7-search: "\f1da";
$ionicon-var-ios7-search-strong: "\f1d9";
$ionicon-var-ios7-settings: "\f339";
$ionicon-var-ios7-settings-strong: "\f338";
$ionicon-var-ios7-shrink: "\f30e";
$ionicon-var-ios7-skipbackward: "\f1dc";
$ionicon-var-ios7-skipbackward-outline: "\f1db";
$ionicon-var-ios7-skipforward: "\f1de";
$ionicon-var-ios7-skipforward-outline: "\f1dd";
$ionicon-var-ios7-snowy: "\f309";
$ionicon-var-ios7-speedometer: "\f290";
$ionicon-var-ios7-speedometer-outline: "\f28f";
$ionicon-var-ios7-star: "\f1e0";
$ionicon-var-ios7-star-half: "\f33a";
$ionicon-var-ios7-star-outline: "\f1df";
$ionicon-var-ios7-stopwatch: "\f1e2";
$ionicon-var-ios7-stopwatch-outline: "\f1e1";
$ionicon-var-ios7-sunny: "\f1e4";
$ionicon-var-ios7-sunny-outline: "\f1e3";
$ionicon-var-ios7-telephone: "\f1e6";
$ionicon-var-ios7-telephone-outline: "\f1e5";
$ionicon-var-ios7-tennisball: "\f33c";
$ionicon-var-ios7-tennisball-outline: "\f33b";
$ionicon-var-ios7-thunderstorm: "\f1e8";
$ionicon-var-ios7-thunderstorm-outline: "\f1e7";
$ionicon-var-ios7-time: "\f292";
$ionicon-var-ios7-time-outline: "\f291";
$ionicon-var-ios7-timer: "\f1ea";
$ionicon-var-ios7-timer-outline: "\f1e9";
$ionicon-var-ios7-toggle: "\f33e";
$ionicon-var-ios7-toggle-outline: "\f33d";
$ionicon-var-ios7-trash: "\f1ec";
$ionicon-var-ios7-trash-outline: "\f1eb";
$ionicon-var-ios7-undo: "\f1ee";
$ionicon-var-ios7-undo-outline: "\f1ed";
$ionicon-var-ios7-unlocked: "\f1f0";
$ionicon-var-ios7-unlocked-outline: "\f1ef";
$ionicon-var-ios7-upload: "\f1f2";
$ionicon-var-ios7-upload-outline: "\f1f1";
$ionicon-var-ios7-videocam: "\f1f4";
$ionicon-var-ios7-videocam-outline: "\f1f3";
$ionicon-var-ios7-volume-high: "\f1f5";
$ionicon-var-ios7-volume-low: "\f1f6";
$ionicon-var-ios7-wineglass: "\f294";
$ionicon-var-ios7-wineglass-outline: "\f293";
$ionicon-var-ios7-world: "\f1f8";
$ionicon-var-ios7-world-outline: "\f1f7";
$ionicon-var-ipad: "\f1f9";
$ionicon-var-iphone: "\f1fa";
$ionicon-var-ipod: "\f1fb";
$ionicon-var-jet: "\f295";
$ionicon-var-key: "\f296";
$ionicon-var-knife: "\f297";
$ionicon-var-laptop: "\f1fc";
$ionicon-var-leaf: "\f1fd";
$ionicon-var-levels: "\f298";
$ionicon-var-lightbulb: "\f299";
$ionicon-var-link: "\f1fe";
$ionicon-var-load-a: "\f29a";
$ionicon-var-load-b: "\f29b";
$ionicon-var-load-c: "\f29c";
$ionicon-var-load-d: "\f29d";
$ionicon-var-location: "\f1ff";
$ionicon-var-locked: "\f200";
$ionicon-var-log-in: "\f29e";
$ionicon-var-log-out: "\f29f";
$ionicon-var-loop: "\f201";
$ionicon-var-magnet: "\f2a0";
$ionicon-var-male: "\f2a1";
$ionicon-var-man: "\f202";
$ionicon-var-map: "\f203";
$ionicon-var-medkit: "\f2a2";
$ionicon-var-merge: "\f33f";
$ionicon-var-mic-a: "\f204";
$ionicon-var-mic-b: "\f205";
$ionicon-var-mic-c: "\f206";
$ionicon-var-minus: "\f209";
$ionicon-var-minus-circled: "\f207";
$ionicon-var-minus-round: "\f208";
$ionicon-var-model-s: "\f2c1";
$ionicon-var-monitor: "\f20a";
$ionicon-var-more: "\f20b";
$ionicon-var-mouse: "\f340";
$ionicon-var-music-note: "\f20c";
$ionicon-var-navicon: "\f20e";
$ionicon-var-navicon-round: "\f20d";
$ionicon-var-navigate: "\f2a3";
$ionicon-var-network: "\f341";
$ionicon-var-no-smoking: "\f2c2";
$ionicon-var-nuclear: "\f2a4";
$ionicon-var-outlet: "\f342";
$ionicon-var-paper-airplane: "\f2c3";
$ionicon-var-paperclip: "\f20f";
$ionicon-var-pause: "\f210";
$ionicon-var-person: "\f213";
$ionicon-var-person-add: "\f211";
$ionicon-var-person-stalker: "\f212";
$ionicon-var-pie-graph: "\f2a5";
$ionicon-var-pin: "\f2a6";
$ionicon-var-pinpoint: "\f2a7";
$ionicon-var-pizza: "\f2a8";
$ionicon-var-plane: "\f214";
$ionicon-var-planet: "\f343";
$ionicon-var-play: "\f215";
$ionicon-var-playstation: "\f30a";
$ionicon-var-plus: "\f218";
$ionicon-var-plus-circled: "\f216";
$ionicon-var-plus-round: "\f217";
$ionicon-var-podium: "\f344";
$ionicon-var-pound: "\f219";
$ionicon-var-power: "\f2a9";
$ionicon-var-pricetag: "\f2aa";
$ionicon-var-pricetags: "\f2ab";
$ionicon-var-printer: "\f21a";
$ionicon-var-pull-request: "\f345";
$ionicon-var-qr-scanner: "\f346";
$ionicon-var-quote: "\f347";
$ionicon-var-radio-waves: "\f2ac";
$ionicon-var-record: "\f21b";
$ionicon-var-refresh: "\f21c";
$ionicon-var-reply: "\f21e";
$ionicon-var-reply-all: "\f21d";
$ionicon-var-ribbon-a: "\f348";
$ionicon-var-ribbon-b: "\f349";
$ionicon-var-sad: "\f34a";
$ionicon-var-scissors: "\f34b";
$ionicon-var-search: "\f21f";
$ionicon-var-settings: "\f2ad";
$ionicon-var-share: "\f220";
$ionicon-var-shuffle: "\f221";
$ionicon-var-skip-backward: "\f222";
$ionicon-var-skip-forward: "\f223";
$ionicon-var-social-android: "\f225";
$ionicon-var-social-android-outline: "\f224";
$ionicon-var-social-apple: "\f227";
$ionicon-var-social-apple-outline: "\f226";
$ionicon-var-social-bitcoin: "\f2af";
$ionicon-var-social-bitcoin-outline: "\f2ae";
$ionicon-var-social-buffer: "\f229";
$ionicon-var-social-buffer-outline: "\f228";
$ionicon-var-social-designernews: "\f22b";
$ionicon-var-social-designernews-outline: "\f22a";
$ionicon-var-social-dribbble: "\f22d";
$ionicon-var-social-dribbble-outline: "\f22c";
$ionicon-var-social-dropbox: "\f22f";
$ionicon-var-social-dropbox-outline: "\f22e";
$ionicon-var-social-facebook: "\f231";
$ionicon-var-social-facebook-outline: "\f230";
$ionicon-var-social-foursquare: "\f34d";
$ionicon-var-social-foursquare-outline: "\f34c";
$ionicon-var-social-freebsd-devil: "\f2c4";
$ionicon-var-social-github: "\f233";
$ionicon-var-social-github-outline: "\f232";
$ionicon-var-social-google: "\f34f";
$ionicon-var-social-google-outline: "\f34e";
$ionicon-var-social-googleplus: "\f235";
$ionicon-var-social-googleplus-outline: "\f234";
$ionicon-var-social-hackernews: "\f237";
$ionicon-var-social-hackernews-outline: "\f236";
$ionicon-var-social-instagram: "\f351";
$ionicon-var-social-instagram-outline: "\f350";
$ionicon-var-social-linkedin: "\f239";
$ionicon-var-social-linkedin-outline: "\f238";
$ionicon-var-social-pinterest: "\f2b1";
$ionicon-var-social-pinterest-outline: "\f2b0";
$ionicon-var-social-reddit: "\f23b";
$ionicon-var-social-reddit-outline: "\f23a";
$ionicon-var-social-rss: "\f23d";
$ionicon-var-social-rss-outline: "\f23c";
$ionicon-var-social-skype: "\f23f";
$ionicon-var-social-skype-outline: "\f23e";
$ionicon-var-social-tumblr: "\f241";
$ionicon-var-social-tumblr-outline: "\f240";
$ionicon-var-social-tux: "\f2c5";
$ionicon-var-social-twitter: "\f243";
$ionicon-var-social-twitter-outline: "\f242";
$ionicon-var-social-usd: "\f353";
$ionicon-var-social-usd-outline: "\f352";
$ionicon-var-social-vimeo: "\f245";
$ionicon-var-social-vimeo-outline: "\f244";
$ionicon-var-social-windows: "\f247";
$ionicon-var-social-windows-outline: "\f246";
$ionicon-var-social-wordpress: "\f249";
$ionicon-var-social-wordpress-outline: "\f248";
$ionicon-var-social-yahoo: "\f24b";
$ionicon-var-social-yahoo-outline: "\f24a";
$ionicon-var-social-youtube: "\f24d";
$ionicon-var-social-youtube-outline: "\f24c";
$ionicon-var-speakerphone: "\f2b2";
$ionicon-var-speedometer: "\f2b3";
$ionicon-var-spoon: "\f2b4";
$ionicon-var-star: "\f24e";
$ionicon-var-stats-bars: "\f2b5";
$ionicon-var-steam: "\f30b";
$ionicon-var-stop: "\f24f";
$ionicon-var-thermometer: "\f2b6";
$ionicon-var-thumbsdown: "\f250";
$ionicon-var-thumbsup: "\f251";
$ionicon-var-toggle: "\f355";
$ionicon-var-toggle-filled: "\f354";
$ionicon-var-trash-a: "\f252";
$ionicon-var-trash-b: "\f253";
$ionicon-var-trophy: "\f356";
$ionicon-var-umbrella: "\f2b7";
$ionicon-var-university: "\f357";
$ionicon-var-unlocked: "\f254";
$ionicon-var-upload: "\f255";
$ionicon-var-usb: "\f2b8";
$ionicon-var-videocamera: "\f256";
$ionicon-var-volume-high: "\f257";
$ionicon-var-volume-low: "\f258";
$ionicon-var-volume-medium: "\f259";
$ionicon-var-volume-mute: "\f25a";
$ionicon-var-wand: "\f358";
$ionicon-var-waterdrop: "\f25b";
$ionicon-var-wifi: "\f25c";
$ionicon-var-wineglass: "\f2b9";
$ionicon-var-woman: "\f25d";
$ionicon-var-wrench: "\f2ba";
$ionicon-var-xbox: "\f30c";