// Ionicons Icons
// --------------------------

.ionicons,
.#{$ionicons-prefix}alert,
.#{$ionicons-prefix}alert-circled,
.#{$ionicons-prefix}android-add,
.#{$ionicons-prefix}android-add-contact,
.#{$ionicons-prefix}android-alarm,
.#{$ionicons-prefix}android-archive,
.#{$ionicons-prefix}android-arrow-back,
.#{$ionicons-prefix}android-arrow-down-left,
.#{$ionicons-prefix}android-arrow-down-right,
.#{$ionicons-prefix}android-arrow-forward,
.#{$ionicons-prefix}android-arrow-up-left,
.#{$ionicons-prefix}android-arrow-up-right,
.#{$ionicons-prefix}android-battery,
.#{$ionicons-prefix}android-book,
.#{$ionicons-prefix}android-calendar,
.#{$ionicons-prefix}android-call,
.#{$ionicons-prefix}android-camera,
.#{$ionicons-prefix}android-chat,
.#{$ionicons-prefix}android-checkmark,
.#{$ionicons-prefix}android-clock,
.#{$ionicons-prefix}android-close,
.#{$ionicons-prefix}android-contact,
.#{$ionicons-prefix}android-contacts,
.#{$ionicons-prefix}android-data,
.#{$ionicons-prefix}android-developer,
.#{$ionicons-prefix}android-display,
.#{$ionicons-prefix}android-download,
.#{$ionicons-prefix}android-drawer,
.#{$ionicons-prefix}android-dropdown,
.#{$ionicons-prefix}android-earth,
.#{$ionicons-prefix}android-folder,
.#{$ionicons-prefix}android-forums,
.#{$ionicons-prefix}android-friends,
.#{$ionicons-prefix}android-hand,
.#{$ionicons-prefix}android-image,
.#{$ionicons-prefix}android-inbox,
.#{$ionicons-prefix}android-information,
.#{$ionicons-prefix}android-keypad,
.#{$ionicons-prefix}android-lightbulb,
.#{$ionicons-prefix}android-locate,
.#{$ionicons-prefix}android-location,
.#{$ionicons-prefix}android-mail,
.#{$ionicons-prefix}android-microphone,
.#{$ionicons-prefix}android-mixer,
.#{$ionicons-prefix}android-more,
.#{$ionicons-prefix}android-note,
.#{$ionicons-prefix}android-playstore,
.#{$ionicons-prefix}android-printer,
.#{$ionicons-prefix}android-promotion,
.#{$ionicons-prefix}android-reminder,
.#{$ionicons-prefix}android-remove,
.#{$ionicons-prefix}android-search,
.#{$ionicons-prefix}android-send,
.#{$ionicons-prefix}android-settings,
.#{$ionicons-prefix}android-share,
.#{$ionicons-prefix}android-social,
.#{$ionicons-prefix}android-social-user,
.#{$ionicons-prefix}android-sort,
.#{$ionicons-prefix}android-stair-drawer,
.#{$ionicons-prefix}android-star,
.#{$ionicons-prefix}android-stopwatch,
.#{$ionicons-prefix}android-storage,
.#{$ionicons-prefix}android-system-back,
.#{$ionicons-prefix}android-system-home,
.#{$ionicons-prefix}android-system-windows,
.#{$ionicons-prefix}android-timer,
.#{$ionicons-prefix}android-trash,
.#{$ionicons-prefix}android-user-menu,
.#{$ionicons-prefix}android-volume,
.#{$ionicons-prefix}android-wifi,
.#{$ionicons-prefix}aperture,
.#{$ionicons-prefix}archive,
.#{$ionicons-prefix}arrow-down-a,
.#{$ionicons-prefix}arrow-down-b,
.#{$ionicons-prefix}arrow-down-c,
.#{$ionicons-prefix}arrow-expand,
.#{$ionicons-prefix}arrow-graph-down-left,
.#{$ionicons-prefix}arrow-graph-down-right,
.#{$ionicons-prefix}arrow-graph-up-left,
.#{$ionicons-prefix}arrow-graph-up-right,
.#{$ionicons-prefix}arrow-left-a,
.#{$ionicons-prefix}arrow-left-b,
.#{$ionicons-prefix}arrow-left-c,
.#{$ionicons-prefix}arrow-move,
.#{$ionicons-prefix}arrow-resize,
.#{$ionicons-prefix}arrow-return-left,
.#{$ionicons-prefix}arrow-return-right,
.#{$ionicons-prefix}arrow-right-a,
.#{$ionicons-prefix}arrow-right-b,
.#{$ionicons-prefix}arrow-right-c,
.#{$ionicons-prefix}arrow-shrink,
.#{$ionicons-prefix}arrow-swap,
.#{$ionicons-prefix}arrow-up-a,
.#{$ionicons-prefix}arrow-up-b,
.#{$ionicons-prefix}arrow-up-c,
.#{$ionicons-prefix}asterisk,
.#{$ionicons-prefix}at,
.#{$ionicons-prefix}bag,
.#{$ionicons-prefix}battery-charging,
.#{$ionicons-prefix}battery-empty,
.#{$ionicons-prefix}battery-full,
.#{$ionicons-prefix}battery-half,
.#{$ionicons-prefix}battery-low,
.#{$ionicons-prefix}beaker,
.#{$ionicons-prefix}beer,
.#{$ionicons-prefix}bluetooth,
.#{$ionicons-prefix}bonfire,
.#{$ionicons-prefix}bookmark,
.#{$ionicons-prefix}briefcase,
.#{$ionicons-prefix}bug,
.#{$ionicons-prefix}calculator,
.#{$ionicons-prefix}calendar,
.#{$ionicons-prefix}camera,
.#{$ionicons-prefix}card,
.#{$ionicons-prefix}cash,
.#{$ionicons-prefix}chatbox,
.#{$ionicons-prefix}chatbox-working,
.#{$ionicons-prefix}chatboxes,
.#{$ionicons-prefix}chatbubble,
.#{$ionicons-prefix}chatbubble-working,
.#{$ionicons-prefix}chatbubbles,
.#{$ionicons-prefix}checkmark,
.#{$ionicons-prefix}checkmark-circled,
.#{$ionicons-prefix}checkmark-round,
.#{$ionicons-prefix}chevron-down,
.#{$ionicons-prefix}chevron-left,
.#{$ionicons-prefix}chevron-right,
.#{$ionicons-prefix}chevron-up,
.#{$ionicons-prefix}clipboard,
.#{$ionicons-prefix}clock,
.#{$ionicons-prefix}close,
.#{$ionicons-prefix}close-circled,
.#{$ionicons-prefix}close-round,
.#{$ionicons-prefix}closed-captioning,
.#{$ionicons-prefix}cloud,
.#{$ionicons-prefix}code,
.#{$ionicons-prefix}code-download,
.#{$ionicons-prefix}code-working,
.#{$ionicons-prefix}coffee,
.#{$ionicons-prefix}compass,
.#{$ionicons-prefix}compose,
.#{$ionicons-prefix}connection-bars,
.#{$ionicons-prefix}contrast,
.#{$ionicons-prefix}cube,
.#{$ionicons-prefix}disc,
.#{$ionicons-prefix}document,
.#{$ionicons-prefix}document-text,
.#{$ionicons-prefix}drag,
.#{$ionicons-prefix}earth,
.#{$ionicons-prefix}edit,
.#{$ionicons-prefix}egg,
.#{$ionicons-prefix}eject,
.#{$ionicons-prefix}email,
.#{$ionicons-prefix}eye,
.#{$ionicons-prefix}eye-disabled,
.#{$ionicons-prefix}female,
.#{$ionicons-prefix}filing,
.#{$ionicons-prefix}film-marker,
.#{$ionicons-prefix}fireball,
.#{$ionicons-prefix}flag,
.#{$ionicons-prefix}flame,
.#{$ionicons-prefix}flash,
.#{$ionicons-prefix}flash-off,
.#{$ionicons-prefix}flask,
.#{$ionicons-prefix}folder,
.#{$ionicons-prefix}fork,
.#{$ionicons-prefix}fork-repo,
.#{$ionicons-prefix}forward,
.#{$ionicons-prefix}funnel,
.#{$ionicons-prefix}game-controller-a,
.#{$ionicons-prefix}game-controller-b,
.#{$ionicons-prefix}gear-a,
.#{$ionicons-prefix}gear-b,
.#{$ionicons-prefix}grid,
.#{$ionicons-prefix}hammer,
.#{$ionicons-prefix}happy,
.#{$ionicons-prefix}headphone,
.#{$ionicons-prefix}heart,
.#{$ionicons-prefix}heart-broken,
.#{$ionicons-prefix}help,
.#{$ionicons-prefix}help-buoy,
.#{$ionicons-prefix}help-circled,
.#{$ionicons-prefix}home,
.#{$ionicons-prefix}icecream,
.#{$ionicons-prefix}icon-social-google-plus,
.#{$ionicons-prefix}icon-social-google-plus-outline,
.#{$ionicons-prefix}image,
.#{$ionicons-prefix}images,
.#{$ionicons-prefix}information,
.#{$ionicons-prefix}information-circled,
.#{$ionicons-prefix}ionic,
.#{$ionicons-prefix}ios7-alarm,
.#{$ionicons-prefix}ios7-alarm-outline,
.#{$ionicons-prefix}ios7-albums,
.#{$ionicons-prefix}ios7-albums-outline,
.#{$ionicons-prefix}ios7-americanfootball,
.#{$ionicons-prefix}ios7-americanfootball-outline,
.#{$ionicons-prefix}ios7-analytics,
.#{$ionicons-prefix}ios7-analytics-outline,
.#{$ionicons-prefix}ios7-arrow-back,
.#{$ionicons-prefix}ios7-arrow-down,
.#{$ionicons-prefix}ios7-arrow-forward,
.#{$ionicons-prefix}ios7-arrow-left,
.#{$ionicons-prefix}ios7-arrow-right,
.#{$ionicons-prefix}ios7-arrow-thin-down,
.#{$ionicons-prefix}ios7-arrow-thin-left,
.#{$ionicons-prefix}ios7-arrow-thin-right,
.#{$ionicons-prefix}ios7-arrow-thin-up,
.#{$ionicons-prefix}ios7-arrow-up,
.#{$ionicons-prefix}ios7-at,
.#{$ionicons-prefix}ios7-at-outline,
.#{$ionicons-prefix}ios7-barcode,
.#{$ionicons-prefix}ios7-barcode-outline,
.#{$ionicons-prefix}ios7-baseball,
.#{$ionicons-prefix}ios7-baseball-outline,
.#{$ionicons-prefix}ios7-basketball,
.#{$ionicons-prefix}ios7-basketball-outline,
.#{$ionicons-prefix}ios7-bell,
.#{$ionicons-prefix}ios7-bell-outline,
.#{$ionicons-prefix}ios7-bolt,
.#{$ionicons-prefix}ios7-bolt-outline,
.#{$ionicons-prefix}ios7-bookmarks,
.#{$ionicons-prefix}ios7-bookmarks-outline,
.#{$ionicons-prefix}ios7-box,
.#{$ionicons-prefix}ios7-box-outline,
.#{$ionicons-prefix}ios7-briefcase,
.#{$ionicons-prefix}ios7-briefcase-outline,
.#{$ionicons-prefix}ios7-browsers,
.#{$ionicons-prefix}ios7-browsers-outline,
.#{$ionicons-prefix}ios7-calculator,
.#{$ionicons-prefix}ios7-calculator-outline,
.#{$ionicons-prefix}ios7-calendar,
.#{$ionicons-prefix}ios7-calendar-outline,
.#{$ionicons-prefix}ios7-camera,
.#{$ionicons-prefix}ios7-camera-outline,
.#{$ionicons-prefix}ios7-cart,
.#{$ionicons-prefix}ios7-cart-outline,
.#{$ionicons-prefix}ios7-chatboxes,
.#{$ionicons-prefix}ios7-chatboxes-outline,
.#{$ionicons-prefix}ios7-chatbubble,
.#{$ionicons-prefix}ios7-chatbubble-outline,
.#{$ionicons-prefix}ios7-checkmark,
.#{$ionicons-prefix}ios7-checkmark-empty,
.#{$ionicons-prefix}ios7-checkmark-outline,
.#{$ionicons-prefix}ios7-circle-filled,
.#{$ionicons-prefix}ios7-circle-outline,
.#{$ionicons-prefix}ios7-clock,
.#{$ionicons-prefix}ios7-clock-outline,
.#{$ionicons-prefix}ios7-close,
.#{$ionicons-prefix}ios7-close-empty,
.#{$ionicons-prefix}ios7-close-outline,
.#{$ionicons-prefix}ios7-cloud,
.#{$ionicons-prefix}ios7-cloud-download,
.#{$ionicons-prefix}ios7-cloud-download-outline,
.#{$ionicons-prefix}ios7-cloud-outline,
.#{$ionicons-prefix}ios7-cloud-upload,
.#{$ionicons-prefix}ios7-cloud-upload-outline,
.#{$ionicons-prefix}ios7-cloudy,
.#{$ionicons-prefix}ios7-cloudy-night,
.#{$ionicons-prefix}ios7-cloudy-night-outline,
.#{$ionicons-prefix}ios7-cloudy-outline,
.#{$ionicons-prefix}ios7-cog,
.#{$ionicons-prefix}ios7-cog-outline,
.#{$ionicons-prefix}ios7-compose,
.#{$ionicons-prefix}ios7-compose-outline,
.#{$ionicons-prefix}ios7-contact,
.#{$ionicons-prefix}ios7-contact-outline,
.#{$ionicons-prefix}ios7-copy,
.#{$ionicons-prefix}ios7-copy-outline,
.#{$ionicons-prefix}ios7-download,
.#{$ionicons-prefix}ios7-download-outline,
.#{$ionicons-prefix}ios7-drag,
.#{$ionicons-prefix}ios7-email,
.#{$ionicons-prefix}ios7-email-outline,
.#{$ionicons-prefix}ios7-expand,
.#{$ionicons-prefix}ios7-eye,
.#{$ionicons-prefix}ios7-eye-outline,
.#{$ionicons-prefix}ios7-fastforward,
.#{$ionicons-prefix}ios7-fastforward-outline,
.#{$ionicons-prefix}ios7-filing,
.#{$ionicons-prefix}ios7-filing-outline,
.#{$ionicons-prefix}ios7-film,
.#{$ionicons-prefix}ios7-film-outline,
.#{$ionicons-prefix}ios7-flag,
.#{$ionicons-prefix}ios7-flag-outline,
.#{$ionicons-prefix}ios7-folder,
.#{$ionicons-prefix}ios7-folder-outline,
.#{$ionicons-prefix}ios7-football,
.#{$ionicons-prefix}ios7-football-outline,
.#{$ionicons-prefix}ios7-gear,
.#{$ionicons-prefix}ios7-gear-outline,
.#{$ionicons-prefix}ios7-glasses,
.#{$ionicons-prefix}ios7-glasses-outline,
.#{$ionicons-prefix}ios7-heart,
.#{$ionicons-prefix}ios7-heart-outline,
.#{$ionicons-prefix}ios7-help,
.#{$ionicons-prefix}ios7-help-empty,
.#{$ionicons-prefix}ios7-help-outline,
.#{$ionicons-prefix}ios7-home,
.#{$ionicons-prefix}ios7-home-outline,
.#{$ionicons-prefix}ios7-infinite,
.#{$ionicons-prefix}ios7-infinite-outline,
.#{$ionicons-prefix}ios7-information,
.#{$ionicons-prefix}ios7-information-empty,
.#{$ionicons-prefix}ios7-information-outline,
.#{$ionicons-prefix}ios7-ionic-outline,
.#{$ionicons-prefix}ios7-keypad,
.#{$ionicons-prefix}ios7-keypad-outline,
.#{$ionicons-prefix}ios7-lightbulb,
.#{$ionicons-prefix}ios7-lightbulb-outline,
.#{$ionicons-prefix}ios7-location,
.#{$ionicons-prefix}ios7-location-outline,
.#{$ionicons-prefix}ios7-locked,
.#{$ionicons-prefix}ios7-locked-outline,
.#{$ionicons-prefix}ios7-loop,
.#{$ionicons-prefix}ios7-loop-strong,
.#{$ionicons-prefix}ios7-medkit,
.#{$ionicons-prefix}ios7-medkit-outline,
.#{$ionicons-prefix}ios7-mic,
.#{$ionicons-prefix}ios7-mic-off,
.#{$ionicons-prefix}ios7-mic-outline,
.#{$ionicons-prefix}ios7-minus,
.#{$ionicons-prefix}ios7-minus-empty,
.#{$ionicons-prefix}ios7-minus-outline,
.#{$ionicons-prefix}ios7-monitor,
.#{$ionicons-prefix}ios7-monitor-outline,
.#{$ionicons-prefix}ios7-moon,
.#{$ionicons-prefix}ios7-moon-outline,
.#{$ionicons-prefix}ios7-more,
.#{$ionicons-prefix}ios7-more-outline,
.#{$ionicons-prefix}ios7-musical-note,
.#{$ionicons-prefix}ios7-musical-notes,
.#{$ionicons-prefix}ios7-navigate,
.#{$ionicons-prefix}ios7-navigate-outline,
.#{$ionicons-prefix}ios7-paper,
.#{$ionicons-prefix}ios7-paper-outline,
.#{$ionicons-prefix}ios7-paperplane,
.#{$ionicons-prefix}ios7-paperplane-outline,
.#{$ionicons-prefix}ios7-partlysunny,
.#{$ionicons-prefix}ios7-partlysunny-outline,
.#{$ionicons-prefix}ios7-pause,
.#{$ionicons-prefix}ios7-pause-outline,
.#{$ionicons-prefix}ios7-paw,
.#{$ionicons-prefix}ios7-paw-outline,
.#{$ionicons-prefix}ios7-people,
.#{$ionicons-prefix}ios7-people-outline,
.#{$ionicons-prefix}ios7-person,
.#{$ionicons-prefix}ios7-person-outline,
.#{$ionicons-prefix}ios7-personadd,
.#{$ionicons-prefix}ios7-personadd-outline,
.#{$ionicons-prefix}ios7-photos,
.#{$ionicons-prefix}ios7-photos-outline,
.#{$ionicons-prefix}ios7-pie,
.#{$ionicons-prefix}ios7-pie-outline,
.#{$ionicons-prefix}ios7-play,
.#{$ionicons-prefix}ios7-play-outline,
.#{$ionicons-prefix}ios7-plus,
.#{$ionicons-prefix}ios7-plus-empty,
.#{$ionicons-prefix}ios7-plus-outline,
.#{$ionicons-prefix}ios7-pricetag,
.#{$ionicons-prefix}ios7-pricetag-outline,
.#{$ionicons-prefix}ios7-pricetags,
.#{$ionicons-prefix}ios7-pricetags-outline,
.#{$ionicons-prefix}ios7-printer,
.#{$ionicons-prefix}ios7-printer-outline,
.#{$ionicons-prefix}ios7-pulse,
.#{$ionicons-prefix}ios7-pulse-strong,
.#{$ionicons-prefix}ios7-rainy,
.#{$ionicons-prefix}ios7-rainy-outline,
.#{$ionicons-prefix}ios7-recording,
.#{$ionicons-prefix}ios7-recording-outline,
.#{$ionicons-prefix}ios7-redo,
.#{$ionicons-prefix}ios7-redo-outline,
.#{$ionicons-prefix}ios7-refresh,
.#{$ionicons-prefix}ios7-refresh-empty,
.#{$ionicons-prefix}ios7-refresh-outline,
.#{$ionicons-prefix}ios7-reload,
.#{$ionicons-prefix}ios7-reverse-camera,
.#{$ionicons-prefix}ios7-reverse-camera-outline,
.#{$ionicons-prefix}ios7-rewind,
.#{$ionicons-prefix}ios7-rewind-outline,
.#{$ionicons-prefix}ios7-search,
.#{$ionicons-prefix}ios7-search-strong,
.#{$ionicons-prefix}ios7-settings,
.#{$ionicons-prefix}ios7-settings-strong,
.#{$ionicons-prefix}ios7-shrink,
.#{$ionicons-prefix}ios7-skipbackward,
.#{$ionicons-prefix}ios7-skipbackward-outline,
.#{$ionicons-prefix}ios7-skipforward,
.#{$ionicons-prefix}ios7-skipforward-outline,
.#{$ionicons-prefix}ios7-snowy,
.#{$ionicons-prefix}ios7-speedometer,
.#{$ionicons-prefix}ios7-speedometer-outline,
.#{$ionicons-prefix}ios7-star,
.#{$ionicons-prefix}ios7-star-half,
.#{$ionicons-prefix}ios7-star-outline,
.#{$ionicons-prefix}ios7-stopwatch,
.#{$ionicons-prefix}ios7-stopwatch-outline,
.#{$ionicons-prefix}ios7-sunny,
.#{$ionicons-prefix}ios7-sunny-outline,
.#{$ionicons-prefix}ios7-telephone,
.#{$ionicons-prefix}ios7-telephone-outline,
.#{$ionicons-prefix}ios7-tennisball,
.#{$ionicons-prefix}ios7-tennisball-outline,
.#{$ionicons-prefix}ios7-thunderstorm,
.#{$ionicons-prefix}ios7-thunderstorm-outline,
.#{$ionicons-prefix}ios7-time,
.#{$ionicons-prefix}ios7-time-outline,
.#{$ionicons-prefix}ios7-timer,
.#{$ionicons-prefix}ios7-timer-outline,
.#{$ionicons-prefix}ios7-toggle,
.#{$ionicons-prefix}ios7-toggle-outline,
.#{$ionicons-prefix}ios7-trash,
.#{$ionicons-prefix}ios7-trash-outline,
.#{$ionicons-prefix}ios7-undo,
.#{$ionicons-prefix}ios7-undo-outline,
.#{$ionicons-prefix}ios7-unlocked,
.#{$ionicons-prefix}ios7-unlocked-outline,
.#{$ionicons-prefix}ios7-upload,
.#{$ionicons-prefix}ios7-upload-outline,
.#{$ionicons-prefix}ios7-videocam,
.#{$ionicons-prefix}ios7-videocam-outline,
.#{$ionicons-prefix}ios7-volume-high,
.#{$ionicons-prefix}ios7-volume-low,
.#{$ionicons-prefix}ios7-wineglass,
.#{$ionicons-prefix}ios7-wineglass-outline,
.#{$ionicons-prefix}ios7-world,
.#{$ionicons-prefix}ios7-world-outline,
.#{$ionicons-prefix}ipad,
.#{$ionicons-prefix}iphone,
.#{$ionicons-prefix}ipod,
.#{$ionicons-prefix}jet,
.#{$ionicons-prefix}key,
.#{$ionicons-prefix}knife,
.#{$ionicons-prefix}laptop,
.#{$ionicons-prefix}leaf,
.#{$ionicons-prefix}levels,
.#{$ionicons-prefix}lightbulb,
.#{$ionicons-prefix}link,
.#{$ionicons-prefix}load-a,
.#{$ionicons-prefix}load-b,
.#{$ionicons-prefix}load-c,
.#{$ionicons-prefix}load-d,
.#{$ionicons-prefix}location,
.#{$ionicons-prefix}locked,
.#{$ionicons-prefix}log-in,
.#{$ionicons-prefix}log-out,
.#{$ionicons-prefix}loop,
.#{$ionicons-prefix}magnet,
.#{$ionicons-prefix}male,
.#{$ionicons-prefix}man,
.#{$ionicons-prefix}map,
.#{$ionicons-prefix}medkit,
.#{$ionicons-prefix}merge,
.#{$ionicons-prefix}mic-a,
.#{$ionicons-prefix}mic-b,
.#{$ionicons-prefix}mic-c,
.#{$ionicons-prefix}minus,
.#{$ionicons-prefix}minus-circled,
.#{$ionicons-prefix}minus-round,
.#{$ionicons-prefix}model-s,
.#{$ionicons-prefix}monitor,
.#{$ionicons-prefix}more,
.#{$ionicons-prefix}mouse,
.#{$ionicons-prefix}music-note,
.#{$ionicons-prefix}navicon,
.#{$ionicons-prefix}navicon-round,
.#{$ionicons-prefix}navigate,
.#{$ionicons-prefix}network,
.#{$ionicons-prefix}no-smoking,
.#{$ionicons-prefix}nuclear,
.#{$ionicons-prefix}outlet,
.#{$ionicons-prefix}paper-airplane,
.#{$ionicons-prefix}paperclip,
.#{$ionicons-prefix}pause,
.#{$ionicons-prefix}person,
.#{$ionicons-prefix}person-add,
.#{$ionicons-prefix}person-stalker,
.#{$ionicons-prefix}pie-graph,
.#{$ionicons-prefix}pin,
.#{$ionicons-prefix}pinpoint,
.#{$ionicons-prefix}pizza,
.#{$ionicons-prefix}plane,
.#{$ionicons-prefix}planet,
.#{$ionicons-prefix}play,
.#{$ionicons-prefix}playstation,
.#{$ionicons-prefix}plus,
.#{$ionicons-prefix}plus-circled,
.#{$ionicons-prefix}plus-round,
.#{$ionicons-prefix}podium,
.#{$ionicons-prefix}pound,
.#{$ionicons-prefix}power,
.#{$ionicons-prefix}pricetag,
.#{$ionicons-prefix}pricetags,
.#{$ionicons-prefix}printer,
.#{$ionicons-prefix}pull-request,
.#{$ionicons-prefix}qr-scanner,
.#{$ionicons-prefix}quote,
.#{$ionicons-prefix}radio-waves,
.#{$ionicons-prefix}record,
.#{$ionicons-prefix}refresh,
.#{$ionicons-prefix}reply,
.#{$ionicons-prefix}reply-all,
.#{$ionicons-prefix}ribbon-a,
.#{$ionicons-prefix}ribbon-b,
.#{$ionicons-prefix}sad,
.#{$ionicons-prefix}scissors,
.#{$ionicons-prefix}search,
.#{$ionicons-prefix}settings,
.#{$ionicons-prefix}share,
.#{$ionicons-prefix}shuffle,
.#{$ionicons-prefix}skip-backward,
.#{$ionicons-prefix}skip-forward,
.#{$ionicons-prefix}social-android,
.#{$ionicons-prefix}social-android-outline,
.#{$ionicons-prefix}social-apple,
.#{$ionicons-prefix}social-apple-outline,
.#{$ionicons-prefix}social-bitcoin,
.#{$ionicons-prefix}social-bitcoin-outline,
.#{$ionicons-prefix}social-buffer,
.#{$ionicons-prefix}social-buffer-outline,
.#{$ionicons-prefix}social-designernews,
.#{$ionicons-prefix}social-designernews-outline,
.#{$ionicons-prefix}social-dribbble,
.#{$ionicons-prefix}social-dribbble-outline,
.#{$ionicons-prefix}social-dropbox,
.#{$ionicons-prefix}social-dropbox-outline,
.#{$ionicons-prefix}social-facebook,
.#{$ionicons-prefix}social-facebook-outline,
.#{$ionicons-prefix}social-foursquare,
.#{$ionicons-prefix}social-foursquare-outline,
.#{$ionicons-prefix}social-freebsd-devil,
.#{$ionicons-prefix}social-github,
.#{$ionicons-prefix}social-github-outline,
.#{$ionicons-prefix}social-google,
.#{$ionicons-prefix}social-google-outline,
.#{$ionicons-prefix}social-googleplus,
.#{$ionicons-prefix}social-googleplus-outline,
.#{$ionicons-prefix}social-hackernews,
.#{$ionicons-prefix}social-hackernews-outline,
.#{$ionicons-prefix}social-instagram,
.#{$ionicons-prefix}social-instagram-outline,
.#{$ionicons-prefix}social-linkedin,
.#{$ionicons-prefix}social-linkedin-outline,
.#{$ionicons-prefix}social-pinterest,
.#{$ionicons-prefix}social-pinterest-outline,
.#{$ionicons-prefix}social-reddit,
.#{$ionicons-prefix}social-reddit-outline,
.#{$ionicons-prefix}social-rss,
.#{$ionicons-prefix}social-rss-outline,
.#{$ionicons-prefix}social-skype,
.#{$ionicons-prefix}social-skype-outline,
.#{$ionicons-prefix}social-tumblr,
.#{$ionicons-prefix}social-tumblr-outline,
.#{$ionicons-prefix}social-tux,
.#{$ionicons-prefix}social-twitter,
.#{$ionicons-prefix}social-twitter-outline,
.#{$ionicons-prefix}social-usd,
.#{$ionicons-prefix}social-usd-outline,
.#{$ionicons-prefix}social-vimeo,
.#{$ionicons-prefix}social-vimeo-outline,
.#{$ionicons-prefix}social-windows,
.#{$ionicons-prefix}social-windows-outline,
.#{$ionicons-prefix}social-wordpress,
.#{$ionicons-prefix}social-wordpress-outline,
.#{$ionicons-prefix}social-yahoo,
.#{$ionicons-prefix}social-yahoo-outline,
.#{$ionicons-prefix}social-youtube,
.#{$ionicons-prefix}social-youtube-outline,
.#{$ionicons-prefix}speakerphone,
.#{$ionicons-prefix}speedometer,
.#{$ionicons-prefix}spoon,
.#{$ionicons-prefix}star,
.#{$ionicons-prefix}stats-bars,
.#{$ionicons-prefix}steam,
.#{$ionicons-prefix}stop,
.#{$ionicons-prefix}thermometer,
.#{$ionicons-prefix}thumbsdown,
.#{$ionicons-prefix}thumbsup,
.#{$ionicons-prefix}toggle,
.#{$ionicons-prefix}toggle-filled,
.#{$ionicons-prefix}trash-a,
.#{$ionicons-prefix}trash-b,
.#{$ionicons-prefix}trophy,
.#{$ionicons-prefix}umbrella,
.#{$ionicons-prefix}university,
.#{$ionicons-prefix}unlocked,
.#{$ionicons-prefix}upload,
.#{$ionicons-prefix}usb,
.#{$ionicons-prefix}videocamera,
.#{$ionicons-prefix}volume-high,
.#{$ionicons-prefix}volume-low,
.#{$ionicons-prefix}volume-medium,
.#{$ionicons-prefix}volume-mute,
.#{$ionicons-prefix}wand,
.#{$ionicons-prefix}waterdrop,
.#{$ionicons-prefix}wifi,
.#{$ionicons-prefix}wineglass,
.#{$ionicons-prefix}woman,
.#{$ionicons-prefix}wrench,
.#{$ionicons-prefix}xbox
{
  @extend .ion;
}
.#{$ionicons-prefix}alert:before { content: $ionicon-var-alert; }
.#{$ionicons-prefix}alert-circled:before { content: $ionicon-var-alert-circled; }
.#{$ionicons-prefix}android-add:before { content: $ionicon-var-android-add; }
.#{$ionicons-prefix}android-add-contact:before { content: $ionicon-var-android-add-contact; }
.#{$ionicons-prefix}android-alarm:before { content: $ionicon-var-android-alarm; }
.#{$ionicons-prefix}android-archive:before { content: $ionicon-var-android-archive; }
.#{$ionicons-prefix}android-arrow-back:before { content: $ionicon-var-android-arrow-back; }
.#{$ionicons-prefix}android-arrow-down-left:before { content: $ionicon-var-android-arrow-down-left; }
.#{$ionicons-prefix}android-arrow-down-right:before { content: $ionicon-var-android-arrow-down-right; }
.#{$ionicons-prefix}android-arrow-forward:before { content: $ionicon-var-android-arrow-forward; }
.#{$ionicons-prefix}android-arrow-up-left:before { content: $ionicon-var-android-arrow-up-left; }
.#{$ionicons-prefix}android-arrow-up-right:before { content: $ionicon-var-android-arrow-up-right; }
.#{$ionicons-prefix}android-battery:before { content: $ionicon-var-android-battery; }
.#{$ionicons-prefix}android-book:before { content: $ionicon-var-android-book; }
.#{$ionicons-prefix}android-calendar:before { content: $ionicon-var-android-calendar; }
.#{$ionicons-prefix}android-call:before { content: $ionicon-var-android-call; }
.#{$ionicons-prefix}android-camera:before { content: $ionicon-var-android-camera; }
.#{$ionicons-prefix}android-chat:before { content: $ionicon-var-android-chat; }
.#{$ionicons-prefix}android-checkmark:before { content: $ionicon-var-android-checkmark; }
.#{$ionicons-prefix}android-clock:before { content: $ionicon-var-android-clock; }
.#{$ionicons-prefix}android-close:before { content: $ionicon-var-android-close; }
.#{$ionicons-prefix}android-contact:before { content: $ionicon-var-android-contact; }
.#{$ionicons-prefix}android-contacts:before { content: $ionicon-var-android-contacts; }
.#{$ionicons-prefix}android-data:before { content: $ionicon-var-android-data; }
.#{$ionicons-prefix}android-developer:before { content: $ionicon-var-android-developer; }
.#{$ionicons-prefix}android-display:before { content: $ionicon-var-android-display; }
.#{$ionicons-prefix}android-download:before { content: $ionicon-var-android-download; }
.#{$ionicons-prefix}android-drawer:before { content: $ionicon-var-android-drawer; }
.#{$ionicons-prefix}android-dropdown:before { content: $ionicon-var-android-dropdown; }
.#{$ionicons-prefix}android-earth:before { content: $ionicon-var-android-earth; }
.#{$ionicons-prefix}android-folder:before { content: $ionicon-var-android-folder; }
.#{$ionicons-prefix}android-forums:before { content: $ionicon-var-android-forums; }
.#{$ionicons-prefix}android-friends:before { content: $ionicon-var-android-friends; }
.#{$ionicons-prefix}android-hand:before { content: $ionicon-var-android-hand; }
.#{$ionicons-prefix}android-image:before { content: $ionicon-var-android-image; }
.#{$ionicons-prefix}android-inbox:before { content: $ionicon-var-android-inbox; }
.#{$ionicons-prefix}android-information:before { content: $ionicon-var-android-information; }
.#{$ionicons-prefix}android-keypad:before { content: $ionicon-var-android-keypad; }
.#{$ionicons-prefix}android-lightbulb:before { content: $ionicon-var-android-lightbulb; }
.#{$ionicons-prefix}android-locate:before { content: $ionicon-var-android-locate; }
.#{$ionicons-prefix}android-location:before { content: $ionicon-var-android-location; }
.#{$ionicons-prefix}android-mail:before { content: $ionicon-var-android-mail; }
.#{$ionicons-prefix}android-microphone:before { content: $ionicon-var-android-microphone; }
.#{$ionicons-prefix}android-mixer:before { content: $ionicon-var-android-mixer; }
.#{$ionicons-prefix}android-more:before { content: $ionicon-var-android-more; }
.#{$ionicons-prefix}android-note:before { content: $ionicon-var-android-note; }
.#{$ionicons-prefix}android-playstore:before { content: $ionicon-var-android-playstore; }
.#{$ionicons-prefix}android-printer:before { content: $ionicon-var-android-printer; }
.#{$ionicons-prefix}android-promotion:before { content: $ionicon-var-android-promotion; }
.#{$ionicons-prefix}android-reminder:before { content: $ionicon-var-android-reminder; }
.#{$ionicons-prefix}android-remove:before { content: $ionicon-var-android-remove; }
.#{$ionicons-prefix}android-search:before { content: $ionicon-var-android-search; }
.#{$ionicons-prefix}android-send:before { content: $ionicon-var-android-send; }
.#{$ionicons-prefix}android-settings:before { content: $ionicon-var-android-settings; }
.#{$ionicons-prefix}android-share:before { content: $ionicon-var-android-share; }
.#{$ionicons-prefix}android-social:before { content: $ionicon-var-android-social; }
.#{$ionicons-prefix}android-social-user:before { content: $ionicon-var-android-social-user; }
.#{$ionicons-prefix}android-sort:before { content: $ionicon-var-android-sort; }
.#{$ionicons-prefix}android-stair-drawer:before { content: $ionicon-var-android-stair-drawer; }
.#{$ionicons-prefix}android-star:before { content: $ionicon-var-android-star; }
.#{$ionicons-prefix}android-stopwatch:before { content: $ionicon-var-android-stopwatch; }
.#{$ionicons-prefix}android-storage:before { content: $ionicon-var-android-storage; }
.#{$ionicons-prefix}android-system-back:before { content: $ionicon-var-android-system-back; }
.#{$ionicons-prefix}android-system-home:before { content: $ionicon-var-android-system-home; }
.#{$ionicons-prefix}android-system-windows:before { content: $ionicon-var-android-system-windows; }
.#{$ionicons-prefix}android-timer:before { content: $ionicon-var-android-timer; }
.#{$ionicons-prefix}android-trash:before { content: $ionicon-var-android-trash; }
.#{$ionicons-prefix}android-user-menu:before { content: $ionicon-var-android-user-menu; }
.#{$ionicons-prefix}android-volume:before { content: $ionicon-var-android-volume; }
.#{$ionicons-prefix}android-wifi:before { content: $ionicon-var-android-wifi; }
.#{$ionicons-prefix}aperture:before { content: $ionicon-var-aperture; }
.#{$ionicons-prefix}archive:before { content: $ionicon-var-archive; }
.#{$ionicons-prefix}arrow-down-a:before { content: $ionicon-var-arrow-down-a; }
.#{$ionicons-prefix}arrow-down-b:before { content: $ionicon-var-arrow-down-b; }
.#{$ionicons-prefix}arrow-down-c:before { content: $ionicon-var-arrow-down-c; }
.#{$ionicons-prefix}arrow-expand:before { content: $ionicon-var-arrow-expand; }
.#{$ionicons-prefix}arrow-graph-down-left:before { content: $ionicon-var-arrow-graph-down-left; }
.#{$ionicons-prefix}arrow-graph-down-right:before { content: $ionicon-var-arrow-graph-down-right; }
.#{$ionicons-prefix}arrow-graph-up-left:before { content: $ionicon-var-arrow-graph-up-left; }
.#{$ionicons-prefix}arrow-graph-up-right:before { content: $ionicon-var-arrow-graph-up-right; }
.#{$ionicons-prefix}arrow-left-a:before { content: $ionicon-var-arrow-left-a; }
.#{$ionicons-prefix}arrow-left-b:before { content: $ionicon-var-arrow-left-b; }
.#{$ionicons-prefix}arrow-left-c:before { content: $ionicon-var-arrow-left-c; }
.#{$ionicons-prefix}arrow-move:before { content: $ionicon-var-arrow-move; }
.#{$ionicons-prefix}arrow-resize:before { content: $ionicon-var-arrow-resize; }
.#{$ionicons-prefix}arrow-return-left:before { content: $ionicon-var-arrow-return-left; }
.#{$ionicons-prefix}arrow-return-right:before { content: $ionicon-var-arrow-return-right; }
.#{$ionicons-prefix}arrow-right-a:before { content: $ionicon-var-arrow-right-a; }
.#{$ionicons-prefix}arrow-right-b:before { content: $ionicon-var-arrow-right-b; }
.#{$ionicons-prefix}arrow-right-c:before { content: $ionicon-var-arrow-right-c; }
.#{$ionicons-prefix}arrow-shrink:before { content: $ionicon-var-arrow-shrink; }
.#{$ionicons-prefix}arrow-swap:before { content: $ionicon-var-arrow-swap; }
.#{$ionicons-prefix}arrow-up-a:before { content: $ionicon-var-arrow-up-a; }
.#{$ionicons-prefix}arrow-up-b:before { content: $ionicon-var-arrow-up-b; }
.#{$ionicons-prefix}arrow-up-c:before { content: $ionicon-var-arrow-up-c; }
.#{$ionicons-prefix}asterisk:before { content: $ionicon-var-asterisk; }
.#{$ionicons-prefix}at:before { content: $ionicon-var-at; }
.#{$ionicons-prefix}bag:before { content: $ionicon-var-bag; }
.#{$ionicons-prefix}battery-charging:before { content: $ionicon-var-battery-charging; }
.#{$ionicons-prefix}battery-empty:before { content: $ionicon-var-battery-empty; }
.#{$ionicons-prefix}battery-full:before { content: $ionicon-var-battery-full; }
.#{$ionicons-prefix}battery-half:before { content: $ionicon-var-battery-half; }
.#{$ionicons-prefix}battery-low:before { content: $ionicon-var-battery-low; }
.#{$ionicons-prefix}beaker:before { content: $ionicon-var-beaker; }
.#{$ionicons-prefix}beer:before { content: $ionicon-var-beer; }
.#{$ionicons-prefix}bluetooth:before { content: $ionicon-var-bluetooth; }
.#{$ionicons-prefix}bonfire:before { content: $ionicon-var-bonfire; }
.#{$ionicons-prefix}bookmark:before { content: $ionicon-var-bookmark; }
.#{$ionicons-prefix}briefcase:before { content: $ionicon-var-briefcase; }
.#{$ionicons-prefix}bug:before { content: $ionicon-var-bug; }
.#{$ionicons-prefix}calculator:before { content: $ionicon-var-calculator; }
.#{$ionicons-prefix}calendar:before { content: $ionicon-var-calendar; }
.#{$ionicons-prefix}camera:before { content: $ionicon-var-camera; }
.#{$ionicons-prefix}card:before { content: $ionicon-var-card; }
.#{$ionicons-prefix}cash:before { content: $ionicon-var-cash; }
.#{$ionicons-prefix}chatbox:before { content: $ionicon-var-chatbox; }
.#{$ionicons-prefix}chatbox-working:before { content: $ionicon-var-chatbox-working; }
.#{$ionicons-prefix}chatboxes:before { content: $ionicon-var-chatboxes; }
.#{$ionicons-prefix}chatbubble:before { content: $ionicon-var-chatbubble; }
.#{$ionicons-prefix}chatbubble-working:before { content: $ionicon-var-chatbubble-working; }
.#{$ionicons-prefix}chatbubbles:before { content: $ionicon-var-chatbubbles; }
.#{$ionicons-prefix}checkmark:before { content: $ionicon-var-checkmark; }
.#{$ionicons-prefix}checkmark-circled:before { content: $ionicon-var-checkmark-circled; }
.#{$ionicons-prefix}checkmark-round:before { content: $ionicon-var-checkmark-round; }
.#{$ionicons-prefix}chevron-down:before { content: $ionicon-var-chevron-down; }
.#{$ionicons-prefix}chevron-left:before { content: $ionicon-var-chevron-left; }
.#{$ionicons-prefix}chevron-right:before { content: $ionicon-var-chevron-right; }
.#{$ionicons-prefix}chevron-up:before { content: $ionicon-var-chevron-up; }
.#{$ionicons-prefix}clipboard:before { content: $ionicon-var-clipboard; }
.#{$ionicons-prefix}clock:before { content: $ionicon-var-clock; }
.#{$ionicons-prefix}close:before { content: $ionicon-var-close; }
.#{$ionicons-prefix}close-circled:before { content: $ionicon-var-close-circled; }
.#{$ionicons-prefix}close-round:before { content: $ionicon-var-close-round; }
.#{$ionicons-prefix}closed-captioning:before { content: $ionicon-var-closed-captioning; }
.#{$ionicons-prefix}cloud:before { content: $ionicon-var-cloud; }
.#{$ionicons-prefix}code:before { content: $ionicon-var-code; }
.#{$ionicons-prefix}code-download:before { content: $ionicon-var-code-download; }
.#{$ionicons-prefix}code-working:before { content: $ionicon-var-code-working; }
.#{$ionicons-prefix}coffee:before { content: $ionicon-var-coffee; }
.#{$ionicons-prefix}compass:before { content: $ionicon-var-compass; }
.#{$ionicons-prefix}compose:before { content: $ionicon-var-compose; }
.#{$ionicons-prefix}connection-bars:before { content: $ionicon-var-connection-bars; }
.#{$ionicons-prefix}contrast:before { content: $ionicon-var-contrast; }
.#{$ionicons-prefix}cube:before { content: $ionicon-var-cube; }
.#{$ionicons-prefix}disc:before { content: $ionicon-var-disc; }
.#{$ionicons-prefix}document:before { content: $ionicon-var-document; }
.#{$ionicons-prefix}document-text:before { content: $ionicon-var-document-text; }
.#{$ionicons-prefix}drag:before { content: $ionicon-var-drag; }
.#{$ionicons-prefix}earth:before { content: $ionicon-var-earth; }
.#{$ionicons-prefix}edit:before { content: $ionicon-var-edit; }
.#{$ionicons-prefix}egg:before { content: $ionicon-var-egg; }
.#{$ionicons-prefix}eject:before { content: $ionicon-var-eject; }
.#{$ionicons-prefix}email:before { content: $ionicon-var-email; }
.#{$ionicons-prefix}eye:before { content: $ionicon-var-eye; }
.#{$ionicons-prefix}eye-disabled:before { content: $ionicon-var-eye-disabled; }
.#{$ionicons-prefix}female:before { content: $ionicon-var-female; }
.#{$ionicons-prefix}filing:before { content: $ionicon-var-filing; }
.#{$ionicons-prefix}film-marker:before { content: $ionicon-var-film-marker; }
.#{$ionicons-prefix}fireball:before { content: $ionicon-var-fireball; }
.#{$ionicons-prefix}flag:before { content: $ionicon-var-flag; }
.#{$ionicons-prefix}flame:before { content: $ionicon-var-flame; }
.#{$ionicons-prefix}flash:before { content: $ionicon-var-flash; }
.#{$ionicons-prefix}flash-off:before { content: $ionicon-var-flash-off; }
.#{$ionicons-prefix}flask:before { content: $ionicon-var-flask; }
.#{$ionicons-prefix}folder:before { content: $ionicon-var-folder; }
.#{$ionicons-prefix}fork:before { content: $ionicon-var-fork; }
.#{$ionicons-prefix}fork-repo:before { content: $ionicon-var-fork-repo; }
.#{$ionicons-prefix}forward:before { content: $ionicon-var-forward; }
.#{$ionicons-prefix}funnel:before { content: $ionicon-var-funnel; }
.#{$ionicons-prefix}game-controller-a:before { content: $ionicon-var-game-controller-a; }
.#{$ionicons-prefix}game-controller-b:before { content: $ionicon-var-game-controller-b; }
.#{$ionicons-prefix}gear-a:before { content: $ionicon-var-gear-a; }
.#{$ionicons-prefix}gear-b:before { content: $ionicon-var-gear-b; }
.#{$ionicons-prefix}grid:before { content: $ionicon-var-grid; }
.#{$ionicons-prefix}hammer:before { content: $ionicon-var-hammer; }
.#{$ionicons-prefix}happy:before { content: $ionicon-var-happy; }
.#{$ionicons-prefix}headphone:before { content: $ionicon-var-headphone; }
.#{$ionicons-prefix}heart:before { content: $ionicon-var-heart; }
.#{$ionicons-prefix}heart-broken:before { content: $ionicon-var-heart-broken; }
.#{$ionicons-prefix}help:before { content: $ionicon-var-help; }
.#{$ionicons-prefix}help-buoy:before { content: $ionicon-var-help-buoy; }
.#{$ionicons-prefix}help-circled:before { content: $ionicon-var-help-circled; }
.#{$ionicons-prefix}home:before { content: $ionicon-var-home; }
.#{$ionicons-prefix}icecream:before { content: $ionicon-var-icecream; }
.#{$ionicons-prefix}icon-social-google-plus:before { content: $ionicon-var-icon-social-google-plus; }
.#{$ionicons-prefix}icon-social-google-plus-outline:before { content: $ionicon-var-icon-social-google-plus-outline; }
.#{$ionicons-prefix}image:before { content: $ionicon-var-image; }
.#{$ionicons-prefix}images:before { content: $ionicon-var-images; }
.#{$ionicons-prefix}information:before { content: $ionicon-var-information; }
.#{$ionicons-prefix}information-circled:before { content: $ionicon-var-information-circled; }
.#{$ionicons-prefix}ionic:before { content: $ionicon-var-ionic; }
.#{$ionicons-prefix}ios7-alarm:before { content: $ionicon-var-ios7-alarm; }
.#{$ionicons-prefix}ios7-alarm-outline:before { content: $ionicon-var-ios7-alarm-outline; }
.#{$ionicons-prefix}ios7-albums:before { content: $ionicon-var-ios7-albums; }
.#{$ionicons-prefix}ios7-albums-outline:before { content: $ionicon-var-ios7-albums-outline; }
.#{$ionicons-prefix}ios7-americanfootball:before { content: $ionicon-var-ios7-americanfootball; }
.#{$ionicons-prefix}ios7-americanfootball-outline:before { content: $ionicon-var-ios7-americanfootball-outline; }
.#{$ionicons-prefix}ios7-analytics:before { content: $ionicon-var-ios7-analytics; }
.#{$ionicons-prefix}ios7-analytics-outline:before { content: $ionicon-var-ios7-analytics-outline; }
.#{$ionicons-prefix}ios7-arrow-back:before { content: $ionicon-var-ios7-arrow-back; }
.#{$ionicons-prefix}ios7-arrow-down:before { content: $ionicon-var-ios7-arrow-down; }
.#{$ionicons-prefix}ios7-arrow-forward:before { content: $ionicon-var-ios7-arrow-forward; }
.#{$ionicons-prefix}ios7-arrow-left:before { content: $ionicon-var-ios7-arrow-left; }
.#{$ionicons-prefix}ios7-arrow-right:before { content: $ionicon-var-ios7-arrow-right; }
.#{$ionicons-prefix}ios7-arrow-thin-down:before { content: $ionicon-var-ios7-arrow-thin-down; }
.#{$ionicons-prefix}ios7-arrow-thin-left:before { content: $ionicon-var-ios7-arrow-thin-left; }
.#{$ionicons-prefix}ios7-arrow-thin-right:before { content: $ionicon-var-ios7-arrow-thin-right; }
.#{$ionicons-prefix}ios7-arrow-thin-up:before { content: $ionicon-var-ios7-arrow-thin-up; }
.#{$ionicons-prefix}ios7-arrow-up:before { content: $ionicon-var-ios7-arrow-up; }
.#{$ionicons-prefix}ios7-at:before { content: $ionicon-var-ios7-at; }
.#{$ionicons-prefix}ios7-at-outline:before { content: $ionicon-var-ios7-at-outline; }
.#{$ionicons-prefix}ios7-barcode:before { content: $ionicon-var-ios7-barcode; }
.#{$ionicons-prefix}ios7-barcode-outline:before { content: $ionicon-var-ios7-barcode-outline; }
.#{$ionicons-prefix}ios7-baseball:before { content: $ionicon-var-ios7-baseball; }
.#{$ionicons-prefix}ios7-baseball-outline:before { content: $ionicon-var-ios7-baseball-outline; }
.#{$ionicons-prefix}ios7-basketball:before { content: $ionicon-var-ios7-basketball; }
.#{$ionicons-prefix}ios7-basketball-outline:before { content: $ionicon-var-ios7-basketball-outline; }
.#{$ionicons-prefix}ios7-bell:before { content: $ionicon-var-ios7-bell; }
.#{$ionicons-prefix}ios7-bell-outline:before { content: $ionicon-var-ios7-bell-outline; }
.#{$ionicons-prefix}ios7-bolt:before { content: $ionicon-var-ios7-bolt; }
.#{$ionicons-prefix}ios7-bolt-outline:before { content: $ionicon-var-ios7-bolt-outline; }
.#{$ionicons-prefix}ios7-bookmarks:before { content: $ionicon-var-ios7-bookmarks; }
.#{$ionicons-prefix}ios7-bookmarks-outline:before { content: $ionicon-var-ios7-bookmarks-outline; }
.#{$ionicons-prefix}ios7-box:before { content: $ionicon-var-ios7-box; }
.#{$ionicons-prefix}ios7-box-outline:before { content: $ionicon-var-ios7-box-outline; }
.#{$ionicons-prefix}ios7-briefcase:before { content: $ionicon-var-ios7-briefcase; }
.#{$ionicons-prefix}ios7-briefcase-outline:before { content: $ionicon-var-ios7-briefcase-outline; }
.#{$ionicons-prefix}ios7-browsers:before { content: $ionicon-var-ios7-browsers; }
.#{$ionicons-prefix}ios7-browsers-outline:before { content: $ionicon-var-ios7-browsers-outline; }
.#{$ionicons-prefix}ios7-calculator:before { content: $ionicon-var-ios7-calculator; }
.#{$ionicons-prefix}ios7-calculator-outline:before { content: $ionicon-var-ios7-calculator-outline; }
.#{$ionicons-prefix}ios7-calendar:before { content: $ionicon-var-ios7-calendar; }
.#{$ionicons-prefix}ios7-calendar-outline:before { content: $ionicon-var-ios7-calendar-outline; }
.#{$ionicons-prefix}ios7-camera:before { content: $ionicon-var-ios7-camera; }
.#{$ionicons-prefix}ios7-camera-outline:before { content: $ionicon-var-ios7-camera-outline; }
.#{$ionicons-prefix}ios7-cart:before { content: $ionicon-var-ios7-cart; }
.#{$ionicons-prefix}ios7-cart-outline:before { content: $ionicon-var-ios7-cart-outline; }
.#{$ionicons-prefix}ios7-chatboxes:before { content: $ionicon-var-ios7-chatboxes; }
.#{$ionicons-prefix}ios7-chatboxes-outline:before { content: $ionicon-var-ios7-chatboxes-outline; }
.#{$ionicons-prefix}ios7-chatbubble:before { content: $ionicon-var-ios7-chatbubble; }
.#{$ionicons-prefix}ios7-chatbubble-outline:before { content: $ionicon-var-ios7-chatbubble-outline; }
.#{$ionicons-prefix}ios7-checkmark:before { content: $ionicon-var-ios7-checkmark; }
.#{$ionicons-prefix}ios7-checkmark-empty:before { content: $ionicon-var-ios7-checkmark-empty; }
.#{$ionicons-prefix}ios7-checkmark-outline:before { content: $ionicon-var-ios7-checkmark-outline; }
.#{$ionicons-prefix}ios7-circle-filled:before { content: $ionicon-var-ios7-circle-filled; }
.#{$ionicons-prefix}ios7-circle-outline:before { content: $ionicon-var-ios7-circle-outline; }
.#{$ionicons-prefix}ios7-clock:before { content: $ionicon-var-ios7-clock; }
.#{$ionicons-prefix}ios7-clock-outline:before { content: $ionicon-var-ios7-clock-outline; }
.#{$ionicons-prefix}ios7-close:before { content: $ionicon-var-ios7-close; }
.#{$ionicons-prefix}ios7-close-empty:before { content: $ionicon-var-ios7-close-empty; }
.#{$ionicons-prefix}ios7-close-outline:before { content: $ionicon-var-ios7-close-outline; }
.#{$ionicons-prefix}ios7-cloud:before { content: $ionicon-var-ios7-cloud; }
.#{$ionicons-prefix}ios7-cloud-download:before { content: $ionicon-var-ios7-cloud-download; }
.#{$ionicons-prefix}ios7-cloud-download-outline:before { content: $ionicon-var-ios7-cloud-download-outline; }
.#{$ionicons-prefix}ios7-cloud-outline:before { content: $ionicon-var-ios7-cloud-outline; }
.#{$ionicons-prefix}ios7-cloud-upload:before { content: $ionicon-var-ios7-cloud-upload; }
.#{$ionicons-prefix}ios7-cloud-upload-outline:before { content: $ionicon-var-ios7-cloud-upload-outline; }
.#{$ionicons-prefix}ios7-cloudy:before { content: $ionicon-var-ios7-cloudy; }
.#{$ionicons-prefix}ios7-cloudy-night:before { content: $ionicon-var-ios7-cloudy-night; }
.#{$ionicons-prefix}ios7-cloudy-night-outline:before { content: $ionicon-var-ios7-cloudy-night-outline; }
.#{$ionicons-prefix}ios7-cloudy-outline:before { content: $ionicon-var-ios7-cloudy-outline; }
.#{$ionicons-prefix}ios7-cog:before { content: $ionicon-var-ios7-cog; }
.#{$ionicons-prefix}ios7-cog-outline:before { content: $ionicon-var-ios7-cog-outline; }
.#{$ionicons-prefix}ios7-compose:before { content: $ionicon-var-ios7-compose; }
.#{$ionicons-prefix}ios7-compose-outline:before { content: $ionicon-var-ios7-compose-outline; }
.#{$ionicons-prefix}ios7-contact:before { content: $ionicon-var-ios7-contact; }
.#{$ionicons-prefix}ios7-contact-outline:before { content: $ionicon-var-ios7-contact-outline; }
.#{$ionicons-prefix}ios7-copy:before { content: $ionicon-var-ios7-copy; }
.#{$ionicons-prefix}ios7-copy-outline:before { content: $ionicon-var-ios7-copy-outline; }
.#{$ionicons-prefix}ios7-download:before { content: $ionicon-var-ios7-download; }
.#{$ionicons-prefix}ios7-download-outline:before { content: $ionicon-var-ios7-download-outline; }
.#{$ionicons-prefix}ios7-drag:before { content: $ionicon-var-ios7-drag; }
.#{$ionicons-prefix}ios7-email:before { content: $ionicon-var-ios7-email; }
.#{$ionicons-prefix}ios7-email-outline:before { content: $ionicon-var-ios7-email-outline; }
.#{$ionicons-prefix}ios7-expand:before { content: $ionicon-var-ios7-expand; }
.#{$ionicons-prefix}ios7-eye:before { content: $ionicon-var-ios7-eye; }
.#{$ionicons-prefix}ios7-eye-outline:before { content: $ionicon-var-ios7-eye-outline; }
.#{$ionicons-prefix}ios7-fastforward:before { content: $ionicon-var-ios7-fastforward; }
.#{$ionicons-prefix}ios7-fastforward-outline:before { content: $ionicon-var-ios7-fastforward-outline; }
.#{$ionicons-prefix}ios7-filing:before { content: $ionicon-var-ios7-filing; }
.#{$ionicons-prefix}ios7-filing-outline:before { content: $ionicon-var-ios7-filing-outline; }
.#{$ionicons-prefix}ios7-film:before { content: $ionicon-var-ios7-film; }
.#{$ionicons-prefix}ios7-film-outline:before { content: $ionicon-var-ios7-film-outline; }
.#{$ionicons-prefix}ios7-flag:before { content: $ionicon-var-ios7-flag; }
.#{$ionicons-prefix}ios7-flag-outline:before { content: $ionicon-var-ios7-flag-outline; }
.#{$ionicons-prefix}ios7-folder:before { content: $ionicon-var-ios7-folder; }
.#{$ionicons-prefix}ios7-folder-outline:before { content: $ionicon-var-ios7-folder-outline; }
.#{$ionicons-prefix}ios7-football:before { content: $ionicon-var-ios7-football; }
.#{$ionicons-prefix}ios7-football-outline:before { content: $ionicon-var-ios7-football-outline; }
.#{$ionicons-prefix}ios7-gear:before { content: $ionicon-var-ios7-gear; }
.#{$ionicons-prefix}ios7-gear-outline:before { content: $ionicon-var-ios7-gear-outline; }
.#{$ionicons-prefix}ios7-glasses:before { content: $ionicon-var-ios7-glasses; }
.#{$ionicons-prefix}ios7-glasses-outline:before { content: $ionicon-var-ios7-glasses-outline; }
.#{$ionicons-prefix}ios7-heart:before { content: $ionicon-var-ios7-heart; }
.#{$ionicons-prefix}ios7-heart-outline:before { content: $ionicon-var-ios7-heart-outline; }
.#{$ionicons-prefix}ios7-help:before { content: $ionicon-var-ios7-help; }
.#{$ionicons-prefix}ios7-help-empty:before { content: $ionicon-var-ios7-help-empty; }
.#{$ionicons-prefix}ios7-help-outline:before { content: $ionicon-var-ios7-help-outline; }
.#{$ionicons-prefix}ios7-home:before { content: $ionicon-var-ios7-home; }
.#{$ionicons-prefix}ios7-home-outline:before { content: $ionicon-var-ios7-home-outline; }
.#{$ionicons-prefix}ios7-infinite:before { content: $ionicon-var-ios7-infinite; }
.#{$ionicons-prefix}ios7-infinite-outline:before { content: $ionicon-var-ios7-infinite-outline; }
.#{$ionicons-prefix}ios7-information:before { content: $ionicon-var-ios7-information; }
.#{$ionicons-prefix}ios7-information-empty:before { content: $ionicon-var-ios7-information-empty; }
.#{$ionicons-prefix}ios7-information-outline:before { content: $ionicon-var-ios7-information-outline; }
.#{$ionicons-prefix}ios7-ionic-outline:before { content: $ionicon-var-ios7-ionic-outline; }
.#{$ionicons-prefix}ios7-keypad:before { content: $ionicon-var-ios7-keypad; }
.#{$ionicons-prefix}ios7-keypad-outline:before { content: $ionicon-var-ios7-keypad-outline; }
.#{$ionicons-prefix}ios7-lightbulb:before { content: $ionicon-var-ios7-lightbulb; }
.#{$ionicons-prefix}ios7-lightbulb-outline:before { content: $ionicon-var-ios7-lightbulb-outline; }
.#{$ionicons-prefix}ios7-location:before { content: $ionicon-var-ios7-location; }
.#{$ionicons-prefix}ios7-location-outline:before { content: $ionicon-var-ios7-location-outline; }
.#{$ionicons-prefix}ios7-locked:before { content: $ionicon-var-ios7-locked; }
.#{$ionicons-prefix}ios7-locked-outline:before { content: $ionicon-var-ios7-locked-outline; }
.#{$ionicons-prefix}ios7-loop:before { content: $ionicon-var-ios7-loop; }
.#{$ionicons-prefix}ios7-loop-strong:before { content: $ionicon-var-ios7-loop-strong; }
.#{$ionicons-prefix}ios7-medkit:before { content: $ionicon-var-ios7-medkit; }
.#{$ionicons-prefix}ios7-medkit-outline:before { content: $ionicon-var-ios7-medkit-outline; }
.#{$ionicons-prefix}ios7-mic:before { content: $ionicon-var-ios7-mic; }
.#{$ionicons-prefix}ios7-mic-off:before { content: $ionicon-var-ios7-mic-off; }
.#{$ionicons-prefix}ios7-mic-outline:before { content: $ionicon-var-ios7-mic-outline; }
.#{$ionicons-prefix}ios7-minus:before { content: $ionicon-var-ios7-minus; }
.#{$ionicons-prefix}ios7-minus-empty:before { content: $ionicon-var-ios7-minus-empty; }
.#{$ionicons-prefix}ios7-minus-outline:before { content: $ionicon-var-ios7-minus-outline; }
.#{$ionicons-prefix}ios7-monitor:before { content: $ionicon-var-ios7-monitor; }
.#{$ionicons-prefix}ios7-monitor-outline:before { content: $ionicon-var-ios7-monitor-outline; }
.#{$ionicons-prefix}ios7-moon:before { content: $ionicon-var-ios7-moon; }
.#{$ionicons-prefix}ios7-moon-outline:before { content: $ionicon-var-ios7-moon-outline; }
.#{$ionicons-prefix}ios7-more:before { content: $ionicon-var-ios7-more; }
.#{$ionicons-prefix}ios7-more-outline:before { content: $ionicon-var-ios7-more-outline; }
.#{$ionicons-prefix}ios7-musical-note:before { content: $ionicon-var-ios7-musical-note; }
.#{$ionicons-prefix}ios7-musical-notes:before { content: $ionicon-var-ios7-musical-notes; }
.#{$ionicons-prefix}ios7-navigate:before { content: $ionicon-var-ios7-navigate; }
.#{$ionicons-prefix}ios7-navigate-outline:before { content: $ionicon-var-ios7-navigate-outline; }
.#{$ionicons-prefix}ios7-paper:before { content: $ionicon-var-ios7-paper; }
.#{$ionicons-prefix}ios7-paper-outline:before { content: $ionicon-var-ios7-paper-outline; }
.#{$ionicons-prefix}ios7-paperplane:before { content: $ionicon-var-ios7-paperplane; }
.#{$ionicons-prefix}ios7-paperplane-outline:before { content: $ionicon-var-ios7-paperplane-outline; }
.#{$ionicons-prefix}ios7-partlysunny:before { content: $ionicon-var-ios7-partlysunny; }
.#{$ionicons-prefix}ios7-partlysunny-outline:before { content: $ionicon-var-ios7-partlysunny-outline; }
.#{$ionicons-prefix}ios7-pause:before { content: $ionicon-var-ios7-pause; }
.#{$ionicons-prefix}ios7-pause-outline:before { content: $ionicon-var-ios7-pause-outline; }
.#{$ionicons-prefix}ios7-paw:before { content: $ionicon-var-ios7-paw; }
.#{$ionicons-prefix}ios7-paw-outline:before { content: $ionicon-var-ios7-paw-outline; }
.#{$ionicons-prefix}ios7-people:before { content: $ionicon-var-ios7-people; }
.#{$ionicons-prefix}ios7-people-outline:before { content: $ionicon-var-ios7-people-outline; }
.#{$ionicons-prefix}ios7-person:before { content: $ionicon-var-ios7-person; }
.#{$ionicons-prefix}ios7-person-outline:before { content: $ionicon-var-ios7-person-outline; }
.#{$ionicons-prefix}ios7-personadd:before { content: $ionicon-var-ios7-personadd; }
.#{$ionicons-prefix}ios7-personadd-outline:before { content: $ionicon-var-ios7-personadd-outline; }
.#{$ionicons-prefix}ios7-photos:before { content: $ionicon-var-ios7-photos; }
.#{$ionicons-prefix}ios7-photos-outline:before { content: $ionicon-var-ios7-photos-outline; }
.#{$ionicons-prefix}ios7-pie:before { content: $ionicon-var-ios7-pie; }
.#{$ionicons-prefix}ios7-pie-outline:before { content: $ionicon-var-ios7-pie-outline; }
.#{$ionicons-prefix}ios7-play:before { content: $ionicon-var-ios7-play; }
.#{$ionicons-prefix}ios7-play-outline:before { content: $ionicon-var-ios7-play-outline; }
.#{$ionicons-prefix}ios7-plus:before { content: $ionicon-var-ios7-plus; }
.#{$ionicons-prefix}ios7-plus-empty:before { content: $ionicon-var-ios7-plus-empty; }
.#{$ionicons-prefix}ios7-plus-outline:before { content: $ionicon-var-ios7-plus-outline; }
.#{$ionicons-prefix}ios7-pricetag:before { content: $ionicon-var-ios7-pricetag; }
.#{$ionicons-prefix}ios7-pricetag-outline:before { content: $ionicon-var-ios7-pricetag-outline; }
.#{$ionicons-prefix}ios7-pricetags:before { content: $ionicon-var-ios7-pricetags; }
.#{$ionicons-prefix}ios7-pricetags-outline:before { content: $ionicon-var-ios7-pricetags-outline; }
.#{$ionicons-prefix}ios7-printer:before { content: $ionicon-var-ios7-printer; }
.#{$ionicons-prefix}ios7-printer-outline:before { content: $ionicon-var-ios7-printer-outline; }
.#{$ionicons-prefix}ios7-pulse:before { content: $ionicon-var-ios7-pulse; }
.#{$ionicons-prefix}ios7-pulse-strong:before { content: $ionicon-var-ios7-pulse-strong; }
.#{$ionicons-prefix}ios7-rainy:before { content: $ionicon-var-ios7-rainy; }
.#{$ionicons-prefix}ios7-rainy-outline:before { content: $ionicon-var-ios7-rainy-outline; }
.#{$ionicons-prefix}ios7-recording:before { content: $ionicon-var-ios7-recording; }
.#{$ionicons-prefix}ios7-recording-outline:before { content: $ionicon-var-ios7-recording-outline; }
.#{$ionicons-prefix}ios7-redo:before { content: $ionicon-var-ios7-redo; }
.#{$ionicons-prefix}ios7-redo-outline:before { content: $ionicon-var-ios7-redo-outline; }
.#{$ionicons-prefix}ios7-refresh:before { content: $ionicon-var-ios7-refresh; }
.#{$ionicons-prefix}ios7-refresh-empty:before { content: $ionicon-var-ios7-refresh-empty; }
.#{$ionicons-prefix}ios7-refresh-outline:before { content: $ionicon-var-ios7-refresh-outline; }
.#{$ionicons-prefix}ios7-reload:before { content: $ionicon-var-ios7-reload; }
.#{$ionicons-prefix}ios7-reverse-camera:before { content: $ionicon-var-ios7-reverse-camera; }
.#{$ionicons-prefix}ios7-reverse-camera-outline:before { content: $ionicon-var-ios7-reverse-camera-outline; }
.#{$ionicons-prefix}ios7-rewind:before { content: $ionicon-var-ios7-rewind; }
.#{$ionicons-prefix}ios7-rewind-outline:before { content: $ionicon-var-ios7-rewind-outline; }
.#{$ionicons-prefix}ios7-search:before { content: $ionicon-var-ios7-search; }
.#{$ionicons-prefix}ios7-search-strong:before { content: $ionicon-var-ios7-search-strong; }
.#{$ionicons-prefix}ios7-settings:before { content: $ionicon-var-ios7-settings; }
.#{$ionicons-prefix}ios7-settings-strong:before { content: $ionicon-var-ios7-settings-strong; }
.#{$ionicons-prefix}ios7-shrink:before { content: $ionicon-var-ios7-shrink; }
.#{$ionicons-prefix}ios7-skipbackward:before { content: $ionicon-var-ios7-skipbackward; }
.#{$ionicons-prefix}ios7-skipbackward-outline:before { content: $ionicon-var-ios7-skipbackward-outline; }
.#{$ionicons-prefix}ios7-skipforward:before { content: $ionicon-var-ios7-skipforward; }
.#{$ionicons-prefix}ios7-skipforward-outline:before { content: $ionicon-var-ios7-skipforward-outline; }
.#{$ionicons-prefix}ios7-snowy:before { content: $ionicon-var-ios7-snowy; }
.#{$ionicons-prefix}ios7-speedometer:before { content: $ionicon-var-ios7-speedometer; }
.#{$ionicons-prefix}ios7-speedometer-outline:before { content: $ionicon-var-ios7-speedometer-outline; }
.#{$ionicons-prefix}ios7-star:before { content: $ionicon-var-ios7-star; }
.#{$ionicons-prefix}ios7-star-half:before { content: $ionicon-var-ios7-star-half; }
.#{$ionicons-prefix}ios7-star-outline:before { content: $ionicon-var-ios7-star-outline; }
.#{$ionicons-prefix}ios7-stopwatch:before { content: $ionicon-var-ios7-stopwatch; }
.#{$ionicons-prefix}ios7-stopwatch-outline:before { content: $ionicon-var-ios7-stopwatch-outline; }
.#{$ionicons-prefix}ios7-sunny:before { content: $ionicon-var-ios7-sunny; }
.#{$ionicons-prefix}ios7-sunny-outline:before { content: $ionicon-var-ios7-sunny-outline; }
.#{$ionicons-prefix}ios7-telephone:before { content: $ionicon-var-ios7-telephone; }
.#{$ionicons-prefix}ios7-telephone-outline:before { content: $ionicon-var-ios7-telephone-outline; }
.#{$ionicons-prefix}ios7-tennisball:before { content: $ionicon-var-ios7-tennisball; }
.#{$ionicons-prefix}ios7-tennisball-outline:before { content: $ionicon-var-ios7-tennisball-outline; }
.#{$ionicons-prefix}ios7-thunderstorm:before { content: $ionicon-var-ios7-thunderstorm; }
.#{$ionicons-prefix}ios7-thunderstorm-outline:before { content: $ionicon-var-ios7-thunderstorm-outline; }
.#{$ionicons-prefix}ios7-time:before { content: $ionicon-var-ios7-time; }
.#{$ionicons-prefix}ios7-time-outline:before { content: $ionicon-var-ios7-time-outline; }
.#{$ionicons-prefix}ios7-timer:before { content: $ionicon-var-ios7-timer; }
.#{$ionicons-prefix}ios7-timer-outline:before { content: $ionicon-var-ios7-timer-outline; }
.#{$ionicons-prefix}ios7-toggle:before { content: $ionicon-var-ios7-toggle; }
.#{$ionicons-prefix}ios7-toggle-outline:before { content: $ionicon-var-ios7-toggle-outline; }
.#{$ionicons-prefix}ios7-trash:before { content: $ionicon-var-ios7-trash; }
.#{$ionicons-prefix}ios7-trash-outline:before { content: $ionicon-var-ios7-trash-outline; }
.#{$ionicons-prefix}ios7-undo:before { content: $ionicon-var-ios7-undo; }
.#{$ionicons-prefix}ios7-undo-outline:before { content: $ionicon-var-ios7-undo-outline; }
.#{$ionicons-prefix}ios7-unlocked:before { content: $ionicon-var-ios7-unlocked; }
.#{$ionicons-prefix}ios7-unlocked-outline:before { content: $ionicon-var-ios7-unlocked-outline; }
.#{$ionicons-prefix}ios7-upload:before { content: $ionicon-var-ios7-upload; }
.#{$ionicons-prefix}ios7-upload-outline:before { content: $ionicon-var-ios7-upload-outline; }
.#{$ionicons-prefix}ios7-videocam:before { content: $ionicon-var-ios7-videocam; }
.#{$ionicons-prefix}ios7-videocam-outline:before { content: $ionicon-var-ios7-videocam-outline; }
.#{$ionicons-prefix}ios7-volume-high:before { content: $ionicon-var-ios7-volume-high; }
.#{$ionicons-prefix}ios7-volume-low:before { content: $ionicon-var-ios7-volume-low; }
.#{$ionicons-prefix}ios7-wineglass:before { content: $ionicon-var-ios7-wineglass; }
.#{$ionicons-prefix}ios7-wineglass-outline:before { content: $ionicon-var-ios7-wineglass-outline; }
.#{$ionicons-prefix}ios7-world:before { content: $ionicon-var-ios7-world; }
.#{$ionicons-prefix}ios7-world-outline:before { content: $ionicon-var-ios7-world-outline; }
.#{$ionicons-prefix}ipad:before { content: $ionicon-var-ipad; }
.#{$ionicons-prefix}iphone:before { content: $ionicon-var-iphone; }
.#{$ionicons-prefix}ipod:before { content: $ionicon-var-ipod; }
.#{$ionicons-prefix}jet:before { content: $ionicon-var-jet; }
.#{$ionicons-prefix}key:before { content: $ionicon-var-key; }
.#{$ionicons-prefix}knife:before { content: $ionicon-var-knife; }
.#{$ionicons-prefix}laptop:before { content: $ionicon-var-laptop; }
.#{$ionicons-prefix}leaf:before { content: $ionicon-var-leaf; }
.#{$ionicons-prefix}levels:before { content: $ionicon-var-levels; }
.#{$ionicons-prefix}lightbulb:before { content: $ionicon-var-lightbulb; }
.#{$ionicons-prefix}link:before { content: $ionicon-var-link; }
.#{$ionicons-prefix}load-a:before { content: $ionicon-var-load-a; }
.#{$ionicons-prefix}load-b:before { content: $ionicon-var-load-b; }
.#{$ionicons-prefix}load-c:before { content: $ionicon-var-load-c; }
.#{$ionicons-prefix}load-d:before { content: $ionicon-var-load-d; }
.#{$ionicons-prefix}location:before { content: $ionicon-var-location; }
.#{$ionicons-prefix}locked:before { content: $ionicon-var-locked; }
.#{$ionicons-prefix}log-in:before { content: $ionicon-var-log-in; }
.#{$ionicons-prefix}log-out:before { content: $ionicon-var-log-out; }
.#{$ionicons-prefix}loop:before { content: $ionicon-var-loop; }
.#{$ionicons-prefix}magnet:before { content: $ionicon-var-magnet; }
.#{$ionicons-prefix}male:before { content: $ionicon-var-male; }
.#{$ionicons-prefix}man:before { content: $ionicon-var-man; }
.#{$ionicons-prefix}map:before { content: $ionicon-var-map; }
.#{$ionicons-prefix}medkit:before { content: $ionicon-var-medkit; }
.#{$ionicons-prefix}merge:before { content: $ionicon-var-merge; }
.#{$ionicons-prefix}mic-a:before { content: $ionicon-var-mic-a; }
.#{$ionicons-prefix}mic-b:before { content: $ionicon-var-mic-b; }
.#{$ionicons-prefix}mic-c:before { content: $ionicon-var-mic-c; }
.#{$ionicons-prefix}minus:before { content: $ionicon-var-minus; }
.#{$ionicons-prefix}minus-circled:before { content: $ionicon-var-minus-circled; }
.#{$ionicons-prefix}minus-round:before { content: $ionicon-var-minus-round; }
.#{$ionicons-prefix}model-s:before { content: $ionicon-var-model-s; }
.#{$ionicons-prefix}monitor:before { content: $ionicon-var-monitor; }
.#{$ionicons-prefix}more:before { content: $ionicon-var-more; }
.#{$ionicons-prefix}mouse:before { content: $ionicon-var-mouse; }
.#{$ionicons-prefix}music-note:before { content: $ionicon-var-music-note; }
.#{$ionicons-prefix}navicon:before { content: $ionicon-var-navicon; }
.#{$ionicons-prefix}navicon-round:before { content: $ionicon-var-navicon-round; }
.#{$ionicons-prefix}navigate:before { content: $ionicon-var-navigate; }
.#{$ionicons-prefix}network:before { content: $ionicon-var-network; }
.#{$ionicons-prefix}no-smoking:before { content: $ionicon-var-no-smoking; }
.#{$ionicons-prefix}nuclear:before { content: $ionicon-var-nuclear; }
.#{$ionicons-prefix}outlet:before { content: $ionicon-var-outlet; }
.#{$ionicons-prefix}paper-airplane:before { content: $ionicon-var-paper-airplane; }
.#{$ionicons-prefix}paperclip:before { content: $ionicon-var-paperclip; }
.#{$ionicons-prefix}pause:before { content: $ionicon-var-pause; }
.#{$ionicons-prefix}person:before { content: $ionicon-var-person; }
.#{$ionicons-prefix}person-add:before { content: $ionicon-var-person-add; }
.#{$ionicons-prefix}person-stalker:before { content: $ionicon-var-person-stalker; }
.#{$ionicons-prefix}pie-graph:before { content: $ionicon-var-pie-graph; }
.#{$ionicons-prefix}pin:before { content: $ionicon-var-pin; }
.#{$ionicons-prefix}pinpoint:before { content: $ionicon-var-pinpoint; }
.#{$ionicons-prefix}pizza:before { content: $ionicon-var-pizza; }
.#{$ionicons-prefix}plane:before { content: $ionicon-var-plane; }
.#{$ionicons-prefix}planet:before { content: $ionicon-var-planet; }
.#{$ionicons-prefix}play:before { content: $ionicon-var-play; }
.#{$ionicons-prefix}playstation:before { content: $ionicon-var-playstation; }
.#{$ionicons-prefix}plus:before { content: $ionicon-var-plus; }
.#{$ionicons-prefix}plus-circled:before { content: $ionicon-var-plus-circled; }
.#{$ionicons-prefix}plus-round:before { content: $ionicon-var-plus-round; }
.#{$ionicons-prefix}podium:before { content: $ionicon-var-podium; }
.#{$ionicons-prefix}pound:before { content: $ionicon-var-pound; }
.#{$ionicons-prefix}power:before { content: $ionicon-var-power; }
.#{$ionicons-prefix}pricetag:before { content: $ionicon-var-pricetag; }
.#{$ionicons-prefix}pricetags:before { content: $ionicon-var-pricetags; }
.#{$ionicons-prefix}printer:before { content: $ionicon-var-printer; }
.#{$ionicons-prefix}pull-request:before { content: $ionicon-var-pull-request; }
.#{$ionicons-prefix}qr-scanner:before { content: $ionicon-var-qr-scanner; }
.#{$ionicons-prefix}quote:before { content: $ionicon-var-quote; }
.#{$ionicons-prefix}radio-waves:before { content: $ionicon-var-radio-waves; }
.#{$ionicons-prefix}record:before { content: $ionicon-var-record; }
.#{$ionicons-prefix}refresh:before { content: $ionicon-var-refresh; }
.#{$ionicons-prefix}reply:before { content: $ionicon-var-reply; }
.#{$ionicons-prefix}reply-all:before { content: $ionicon-var-reply-all; }
.#{$ionicons-prefix}ribbon-a:before { content: $ionicon-var-ribbon-a; }
.#{$ionicons-prefix}ribbon-b:before { content: $ionicon-var-ribbon-b; }
.#{$ionicons-prefix}sad:before { content: $ionicon-var-sad; }
.#{$ionicons-prefix}scissors:before { content: $ionicon-var-scissors; }
.#{$ionicons-prefix}search:before { content: $ionicon-var-search; }
.#{$ionicons-prefix}settings:before { content: $ionicon-var-settings; }
.#{$ionicons-prefix}share:before { content: $ionicon-var-share; }
.#{$ionicons-prefix}shuffle:before { content: $ionicon-var-shuffle; }
.#{$ionicons-prefix}skip-backward:before { content: $ionicon-var-skip-backward; }
.#{$ionicons-prefix}skip-forward:before { content: $ionicon-var-skip-forward; }
.#{$ionicons-prefix}social-android:before { content: $ionicon-var-social-android; }
.#{$ionicons-prefix}social-android-outline:before { content: $ionicon-var-social-android-outline; }
.#{$ionicons-prefix}social-apple:before { content: $ionicon-var-social-apple; }
.#{$ionicons-prefix}social-apple-outline:before { content: $ionicon-var-social-apple-outline; }
.#{$ionicons-prefix}social-bitcoin:before { content: $ionicon-var-social-bitcoin; }
.#{$ionicons-prefix}social-bitcoin-outline:before { content: $ionicon-var-social-bitcoin-outline; }
.#{$ionicons-prefix}social-buffer:before { content: $ionicon-var-social-buffer; }
.#{$ionicons-prefix}social-buffer-outline:before { content: $ionicon-var-social-buffer-outline; }
.#{$ionicons-prefix}social-designernews:before { content: $ionicon-var-social-designernews; }
.#{$ionicons-prefix}social-designernews-outline:before { content: $ionicon-var-social-designernews-outline; }
.#{$ionicons-prefix}social-dribbble:before { content: $ionicon-var-social-dribbble; }
.#{$ionicons-prefix}social-dribbble-outline:before { content: $ionicon-var-social-dribbble-outline; }
.#{$ionicons-prefix}social-dropbox:before { content: $ionicon-var-social-dropbox; }
.#{$ionicons-prefix}social-dropbox-outline:before { content: $ionicon-var-social-dropbox-outline; }
.#{$ionicons-prefix}social-facebook:before { content: $ionicon-var-social-facebook; }
.#{$ionicons-prefix}social-facebook-outline:before { content: $ionicon-var-social-facebook-outline; }
.#{$ionicons-prefix}social-foursquare:before { content: $ionicon-var-social-foursquare; }
.#{$ionicons-prefix}social-foursquare-outline:before { content: $ionicon-var-social-foursquare-outline; }
.#{$ionicons-prefix}social-freebsd-devil:before { content: $ionicon-var-social-freebsd-devil; }
.#{$ionicons-prefix}social-github:before { content: $ionicon-var-social-github; }
.#{$ionicons-prefix}social-github-outline:before { content: $ionicon-var-social-github-outline; }
.#{$ionicons-prefix}social-google:before { content: $ionicon-var-social-google; }
.#{$ionicons-prefix}social-google-outline:before { content: $ionicon-var-social-google-outline; }
.#{$ionicons-prefix}social-googleplus:before { content: $ionicon-var-social-googleplus; }
.#{$ionicons-prefix}social-googleplus-outline:before { content: $ionicon-var-social-googleplus-outline; }
.#{$ionicons-prefix}social-hackernews:before { content: $ionicon-var-social-hackernews; }
.#{$ionicons-prefix}social-hackernews-outline:before { content: $ionicon-var-social-hackernews-outline; }
.#{$ionicons-prefix}social-instagram:before { content: $ionicon-var-social-instagram; }
.#{$ionicons-prefix}social-instagram-outline:before { content: $ionicon-var-social-instagram-outline; }
.#{$ionicons-prefix}social-linkedin:before { content: $ionicon-var-social-linkedin; }
.#{$ionicons-prefix}social-linkedin-outline:before { content: $ionicon-var-social-linkedin-outline; }
.#{$ionicons-prefix}social-pinterest:before { content: $ionicon-var-social-pinterest; }
.#{$ionicons-prefix}social-pinterest-outline:before { content: $ionicon-var-social-pinterest-outline; }
.#{$ionicons-prefix}social-reddit:before { content: $ionicon-var-social-reddit; }
.#{$ionicons-prefix}social-reddit-outline:before { content: $ionicon-var-social-reddit-outline; }
.#{$ionicons-prefix}social-rss:before { content: $ionicon-var-social-rss; }
.#{$ionicons-prefix}social-rss-outline:before { content: $ionicon-var-social-rss-outline; }
.#{$ionicons-prefix}social-skype:before { content: $ionicon-var-social-skype; }
.#{$ionicons-prefix}social-skype-outline:before { content: $ionicon-var-social-skype-outline; }
.#{$ionicons-prefix}social-tumblr:before { content: $ionicon-var-social-tumblr; }
.#{$ionicons-prefix}social-tumblr-outline:before { content: $ionicon-var-social-tumblr-outline; }
.#{$ionicons-prefix}social-tux:before { content: $ionicon-var-social-tux; }
.#{$ionicons-prefix}social-twitter:before { content: $ionicon-var-social-twitter; }
.#{$ionicons-prefix}social-twitter-outline:before { content: $ionicon-var-social-twitter-outline; }
.#{$ionicons-prefix}social-usd:before { content: $ionicon-var-social-usd; }
.#{$ionicons-prefix}social-usd-outline:before { content: $ionicon-var-social-usd-outline; }
.#{$ionicons-prefix}social-vimeo:before { content: $ionicon-var-social-vimeo; }
.#{$ionicons-prefix}social-vimeo-outline:before { content: $ionicon-var-social-vimeo-outline; }
.#{$ionicons-prefix}social-windows:before { content: $ionicon-var-social-windows; }
.#{$ionicons-prefix}social-windows-outline:before { content: $ionicon-var-social-windows-outline; }
.#{$ionicons-prefix}social-wordpress:before { content: $ionicon-var-social-wordpress; }
.#{$ionicons-prefix}social-wordpress-outline:before { content: $ionicon-var-social-wordpress-outline; }
.#{$ionicons-prefix}social-yahoo:before { content: $ionicon-var-social-yahoo; }
.#{$ionicons-prefix}social-yahoo-outline:before { content: $ionicon-var-social-yahoo-outline; }
.#{$ionicons-prefix}social-youtube:before { content: $ionicon-var-social-youtube; }
.#{$ionicons-prefix}social-youtube-outline:before { content: $ionicon-var-social-youtube-outline; }
.#{$ionicons-prefix}speakerphone:before { content: $ionicon-var-speakerphone; }
.#{$ionicons-prefix}speedometer:before { content: $ionicon-var-speedometer; }
.#{$ionicons-prefix}spoon:before { content: $ionicon-var-spoon; }
.#{$ionicons-prefix}star:before { content: $ionicon-var-star; }
.#{$ionicons-prefix}stats-bars:before { content: $ionicon-var-stats-bars; }
.#{$ionicons-prefix}steam:before { content: $ionicon-var-steam; }
.#{$ionicons-prefix}stop:before { content: $ionicon-var-stop; }
.#{$ionicons-prefix}thermometer:before { content: $ionicon-var-thermometer; }
.#{$ionicons-prefix}thumbsdown:before { content: $ionicon-var-thumbsdown; }
.#{$ionicons-prefix}thumbsup:before { content: $ionicon-var-thumbsup; }
.#{$ionicons-prefix}toggle:before { content: $ionicon-var-toggle; }
.#{$ionicons-prefix}toggle-filled:before { content: $ionicon-var-toggle-filled; }
.#{$ionicons-prefix}trash-a:before { content: $ionicon-var-trash-a; }
.#{$ionicons-prefix}trash-b:before { content: $ionicon-var-trash-b; }
.#{$ionicons-prefix}trophy:before { content: $ionicon-var-trophy; }
.#{$ionicons-prefix}umbrella:before { content: $ionicon-var-umbrella; }
.#{$ionicons-prefix}university:before { content: $ionicon-var-university; }
.#{$ionicons-prefix}unlocked:before { content: $ionicon-var-unlocked; }
.#{$ionicons-prefix}upload:before { content: $ionicon-var-upload; }
.#{$ionicons-prefix}usb:before { content: $ionicon-var-usb; }
.#{$ionicons-prefix}videocamera:before { content: $ionicon-var-videocamera; }
.#{$ionicons-prefix}volume-high:before { content: $ionicon-var-volume-high; }
.#{$ionicons-prefix}volume-low:before { content: $ionicon-var-volume-low; }
.#{$ionicons-prefix}volume-medium:before { content: $ionicon-var-volume-medium; }
.#{$ionicons-prefix}volume-mute:before { content: $ionicon-var-volume-mute; }
.#{$ionicons-prefix}wand:before { content: $ionicon-var-wand; }
.#{$ionicons-prefix}waterdrop:before { content: $ionicon-var-waterdrop; }
.#{$ionicons-prefix}wifi:before { content: $ionicon-var-wifi; }
.#{$ionicons-prefix}wineglass:before { content: $ionicon-var-wineglass; }
.#{$ionicons-prefix}woman:before { content: $ionicon-var-woman; }
.#{$ionicons-prefix}wrench:before { content: $ionicon-var-wrench; }
.#{$ionicons-prefix}xbox:before { content: $ionicon-var-xbox; }